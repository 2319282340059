"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteAlertCollapse = void 0;
class SiteAlertCollapse {
    constructor(element, options) {
        this.customEvents = {};
        this.eventListener = () => this.handleClick();
        this.element = element;
        this.options = options;
        this.collapsibleContent = this.element.querySelector('.usa-alert__nci-content');
        this.collapsibleContent.setAttribute('id', `${this.element.id}-content`);
        this.button = this.createButton();
        this.initialize();
    }
    unregister() {
        this.collapsibleContent.removeAttribute('aria-hidden');
        this.toggleCollapseA11y();
        this.button.remove();
        this.removeEventListeners();
    }
    initialize() {
        var _a;
        this.createCustomEvents();
        this.addButton();
        this.addEventListeners();
        const cookie = (_a = document.cookie
            .match(`(^|;)\\s*USASiteAlert${this.element.id}\\s*=\\s*([^;]+)`)) === null || _a === void 0 ? void 0 : _a.pop();
        if (!cookie) {
            this.toggleCollapse();
        }
        else {
            this.setFromCookie(cookie);
        }
    }
    createButton() {
        const button = document.createElement('button');
        button.classList.add('usa-alert__nci-button', this.options.collapseButtonClass);
        button.setAttribute('aria-controls', this.collapsibleContent.id);
        button.setAttribute('aria-expanded', 'false');
        button.setAttribute('aria-label', this.options.collapseAriaLabel);
        button.innerHTML =
            '<svg class="usa-icon" role="img" aria-hidden="true" viewBox="0 0 64 39"><path fill="currentColor" d="M.655 34.187c-.427-.437-.64-.937-.64-1.503 0-.566.213-1.067.64-1.502L30.542.756c.427-.436.918-.653 1.474-.653.555 0 1.048.218 1.474.653l29.884 30.426c.428.435.642.936.642 1.502s-.213 1.066-.642 1.501l-3.206 3.265c-.427.436-.919.653-1.475.653-.555 0-1.047-.217-1.475-.653L32.016 11.79 6.81 37.45c-.427.436-.919.653-1.474.653-.556 0-1.048-.217-1.475-.653L.655 34.187z"></path></svg>';
        return button;
    }
    addButton() {
        const header = this.element.querySelector('.usa-alert__nci-header');
        header.append(this.button);
    }
    toggleCollapse() {
        this.collapsibleContent.ariaHidden === 'true'
            ? (this.collapsibleContent.ariaHidden = 'false')
            : (this.collapsibleContent.ariaHidden = 'true');
        this.toggleCollapseA11y();
        const event = this.collapsibleContent.ariaHidden ? 'collapse' : 'expand';
        this.element.dispatchEvent(this.customEvents[event]);
        document.cookie = `USASiteAlert${this.element.id}=${event}; Path=${this.options.collapseCookiePath}`;
    }
    toggleCollapseA11y() {
        const hidden = this.collapsibleContent.ariaHidden;
        const expanded = hidden === 'true';
        this.button.setAttribute('aria-expanded', String(!expanded));
        const controls = this.button.getAttribute('aria-controls');
        const content = this.element.querySelector(`#${controls}`);
        content.setAttribute('aria-hidden', String(hidden));
    }
    setFromCookie(cookie) {
        if (cookie === 'collapse') {
            this.collapsibleContent.ariaHidden = 'true';
        }
        else if (cookie === 'expand') {
            this.collapsibleContent.ariaHidden = 'false';
        }
        this.toggleCollapseA11y();
    }
    addEventListeners() {
        this.button.addEventListener('click', this.eventListener);
    }
    removeEventListeners() {
        this.button.removeEventListener('click', this.eventListener);
    }
    handleClick() {
        this.toggleCollapse();
    }
    createCustomEvents() {
        const events = ['collapse', 'expand'];
        [...events].forEach((event) => {
            this.customEvents[event] = new CustomEvent(`${this.options.collapseEventListenerLabel}:${event}`, {
                detail: this.element,
            });
        });
    }
}
exports.SiteAlertCollapse = SiteAlertCollapse;
