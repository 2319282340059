import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Templates`}</h1>
    <p>{`Templates are the sets of recommended patterns and examples created using NCIDS and U.S. Web Design System (USWDS) components. These templates are based on best practice recommendations and provide solutions addressing common use cases and organizational needs.`}</p>
    <h2>{`Patterns`}</h2>
    <a href="/templates/3-card-row" className="header-styled-link">
	3-Card Row
    </a>
    <p>{`A 3-card row is a pattern of three cards united by a common theme, subject, or content type.`}</p>
    <a href="/templates/guide-card-row" className="header-styled-link">
	Guide Card Row
    </a>
    <p>{`A guide card row is a pattern of two or three guide cards, each providing a set of 2-6 related resources.`}</p>
    <a href="/templates/summary-box-row" className="header-styled-link">
	Summary Box Row
    </a>
    <p>{`A summary box row is a pattern of two summary box components each displaying different types of key information or next steps.`}</p>
    <h2>{`Example NCIDS Websites`}</h2>
    <p>{`Browse through example websites that use the NCIDS and USWDS components and patterns.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/example-site/example-pages/cgov-home.html"
        }}>{`Cancer.gov Home Page`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/example-site/example-pages/landing-page-with-title.html"
        }}>{`Landing Page with Title`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/example-site/inner-pages/inner-page.html"
        }}>{`Inner Page`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      