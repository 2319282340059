"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NCIAutocomplete = void 0;
class NCIAutocomplete {
    constructor(autocompleteInput, options) {
        this.optionsListNumber = null;
        this.optionClickEventListener = (event) => this.handleOptionClick(event);
        this.inputEventListener = () => this.handleInput();
        this.keyPressEventListener = (event) => this.handleKeypress(event);
        this.submissionEventListener = () => this.handleFormSubmission();
        this.outsideClickListener = (event) => this.handleOutsideClick(event);
        this.autocompleteInput = autocompleteInput;
        this.autocompleteInputId = this.autocompleteInput.id;
        this.options = Object.assign(Object.assign({ autocompleteSource: undefined }, NCIAutocomplete.optionDefaults), options);
        if (!this.options.autocompleteSource) {
            throw 'option autocompleteSource is undefined';
        }
        this.optionsListDisplayed = false;
        this.selectedOptionInfo = {
            inputtedTextWhenSelected: '',
            selectedOptionIndex: null,
            selectedOptionValue: null,
        };
        this.adapter = this.options.autocompleteSource;
        this.acForm = this.autocompleteInput.closest('form');
        this.acInputParent = this.autocompleteInput.parentElement;
        this.autocompleteContainer = document.createElement('div');
        this.autocompleteContainer.classList.add('nci-autocomplete');
        while (this.acInputParent.firstChild) {
            const nodeToMove = this.acInputParent.firstChild;
            this.acInputParent.removeChild(this.acInputParent.firstChild);
            this.autocompleteContainer.appendChild(nodeToMove);
        }
        this.acInputParent.append(this.autocompleteContainer);
        this.listboxWrapper = document.createElement('div');
        this.listbox = document.createElement('div');
        this.announcer = document.createElement('div');
        this.initialize();
    }
    static create(element, options) {
        if (!(element instanceof HTMLInputElement)) {
            throw 'Must be an input element to be an autocomplete';
        }
        return new this(element, options);
    }
    initialize() {
        this.updateDom();
    }
    updateDom() {
        this.listboxWrapper.setAttribute('id', this.autocompleteInputId + '-termswrapper');
        this.listboxWrapper.classList.add('nci-autocomplete__listbox');
        if (this.options.listboxClasses !== '') {
            this.listboxWrapper.classList.add(this.options.listboxClasses);
        }
        this.listbox.setAttribute('id', this.autocompleteInputId + '-terms');
        this.listbox.setAttribute('tabindex', '-1');
        this.listbox.setAttribute('role', 'listbox');
        this.listboxWrapper.append(this.listbox);
        this.autocompleteContainer.append(this.listboxWrapper);
        this.announcer.classList.add('nci-autocomplete__status');
        this.announcer.setAttribute('aria-live', 'assertive');
        this.autocompleteContainer.prepend(this.announcer);
        this.autocompleteInput.setAttribute('role', 'combobox');
        this.autocompleteInput.setAttribute('aria-autocomplete', 'list');
        this.autocompleteInput.setAttribute('aria-haspopup', 'listbox');
        this.autocompleteInput.setAttribute('aria-expanded', 'false');
        this.autocompleteInput.setAttribute('aria-owns', this.autocompleteInputId + '-terms');
        this.autocompleteInput.setAttribute('aria-activedescendant', '');
        this.addEventListeners();
    }
    addEventListeners() {
        this.autocompleteInput.addEventListener('input', this.inputEventListener, true);
        this.autocompleteInput.addEventListener('keydown', this.keyPressEventListener, true);
        this.acForm.addEventListener('submit', this.submissionEventListener, true);
        window.addEventListener('click', this.outsideClickListener, true);
    }
    closeListbox() {
        this.autocompleteInput.removeAttribute('aria-activedescendant');
        this.autocompleteInput.setAttribute('aria-expanded', 'false');
        this.announcer.innerHTML = '';
        this.listbox.innerHTML = '';
        this.listboxWrapper.classList.remove('active');
    }
    handleFormSubmission() {
        const formDetail = Object.assign({ searchText: this.autocompleteInput.value, optionsPresented: this.optionsListDisplayed, optionSetSize: this.optionsListNumber }, this.selectedOptionInfo);
        this.autocompleteInput.dispatchEvent(new CustomEvent('nci-autocomplete:formSubmission', {
            bubbles: true,
            detail: formDetail,
        }));
        this.selectedOptionInfo = {
            inputtedTextWhenSelected: '',
            selectedOptionIndex: null,
            selectedOptionValue: null,
        };
    }
    handleInput() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.autocompleteInput.value.length >= this.options.minCharCount) {
                const response = yield this.adapter.getAutocompleteSuggestions(this.autocompleteInput.value);
                this.buildTermsList(response);
            }
            else if (this.options.minPlaceholderMsg &&
                this.autocompleteInput.value.length < this.options.minCharCount &&
                this.autocompleteInput.value.length > 0) {
                this.optionsListDisplayed = false;
                this.listbox.innerHTML = `<div class="nci-autocomplete__option"><span class="min-placeholder-message">${this.options.minPlaceholderMsg}</span></div>`;
                this.listboxWrapper.classList.add('active');
            }
            else {
                this.optionsListDisplayed = false;
                this.closeListbox();
            }
            return;
        });
    }
    handleOutsideClick(event) {
        if (this.listboxWrapper.classList.contains('active')) {
            const target = event.target;
            if (!target.matches('nci-autocomplete__option')) {
                this.closeListbox();
            }
        }
        else {
            return;
        }
    }
    handleKeypress(event) {
        var _a;
        const keyboardEvt = event;
        const termHighlighted = ((_a = this.listbox.querySelectorAll('.highlight')) === null || _a === void 0 ? void 0 : _a.length) > 0;
        switch (keyboardEvt.key) {
            case 'Escape':
            case 'Tab':
                this.closeListbox();
                break;
            case 'Enter':
                if (termHighlighted) {
                    event.preventDefault();
                    event.stopPropagation();
                    return this.selectOption(termHighlighted);
                }
                else {
                    return;
                }
            case 'ArrowRight':
                return this.selectOption(termHighlighted);
            case 'ArrowUp':
                event.preventDefault();
                event.stopPropagation();
                return this.moveUp(termHighlighted);
            case 'ArrowDown':
                event.preventDefault();
                event.stopPropagation();
                return this.moveDown(termHighlighted);
            default:
                return;
        }
    }
    moveUp(highlighted) {
        if (highlighted) {
            const currentTerm = this.listbox.querySelector('.highlight');
            currentTerm.setAttribute('aria-selected', 'false');
            currentTerm.classList.remove('highlight');
            const prevTerm = currentTerm.previousSibling;
            prevTerm.setAttribute('aria-selected', 'true');
            prevTerm.classList.add('highlight');
            this.autocompleteInput.setAttribute('aria-activedescendant', prevTerm.id);
        }
        else {
            const terms = this.listbox.querySelectorAll('.nci-autocomplete__option');
            const lastTerm = terms[terms.length - 1];
            if (lastTerm != null) {
                lastTerm.classList.add('highlight');
                lastTerm.setAttribute('aria-selected', 'true');
                this.autocompleteInput.setAttribute('aria-activedescendant', lastTerm.id);
            }
        }
    }
    moveDown(highlighted) {
        if (highlighted) {
            const currentTerm = this.listbox.querySelector('.highlight');
            currentTerm.setAttribute('aria-selected', 'false');
            currentTerm.classList.remove('highlight');
            const nextTerm = currentTerm.nextSibling;
            nextTerm.setAttribute('aria-selected', 'true');
            nextTerm.classList.add('highlight');
            this.autocompleteInput.setAttribute('aria-activedescendant', nextTerm.id);
        }
        else {
            const terms = this.listbox.querySelectorAll('.nci-autocomplete__option');
            const firstTerm = terms[0];
            firstTerm.classList.add('highlight');
            firstTerm.setAttribute('aria-selected', 'true');
            this.autocompleteInput.setAttribute('aria-activedescendant', firstTerm.id);
        }
    }
    selectOption(termHighlighted) {
        if (termHighlighted && this.autocompleteInput) {
            const selectedOption = this.listbox.querySelector('.highlight');
            const selectedOptionSpan = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.querySelector('span');
            this.selectedOptionInfo = {
                inputtedTextWhenSelected: this.autocompleteInput.value,
                selectedOptionValue: selectedOptionSpan.getAttribute('aria-label') || '',
                selectedOptionIndex: Number(selectedOption.getAttribute('aria-posinset') || '') || 0,
            };
            this.autocompleteInput.dispatchEvent(new CustomEvent('nci-autocomplete:optionSelected', {
                detail: this.selectedOptionInfo,
            }));
            this.autocompleteInput.removeAttribute('aria-activedescendant');
            this.autocompleteInput.value =
                selectedOptionSpan.getAttribute('aria-label') || '';
            this.autocompleteInput.focus();
            this.closeListbox();
        }
    }
    markInputMatch(termText) {
        return termText.replace(new RegExp(this.autocompleteInput.value, 'gi'), (match) => `<mark>${match}</mark>`);
    }
    buildTermsList(termsArr) {
        this.updateAnnouncer(termsArr.length);
        if (termsArr.length < 1) {
            this.optionsListDisplayed = false;
            this.listbox.innerHTML = '';
            this.listboxWrapper.classList.remove('active');
            this.optionsListNumber = 0;
            this.closeListbox();
        }
        else {
            const termsList = termsArr.map((term, idx) => {
                this.optionsListNumber = termsArr.length;
                return idx < this.options.maxOptionsCount
                    ? `<div
            class="nci-autocomplete__option"
            tabindex="-1"
            role="option"
            aria-posinset="${idx}"
            aria-setsize="${termsArr.length}"
            id="term-${idx}">
              <span aria-label="${term}">${this.options.highlightMatchingText
                        ? this.markInputMatch(term)
                        : term}</span>
            </div>`
                    : '';
            });
            this.listbox.innerHTML = termsList.join('');
            this.listboxWrapper.classList.add('active');
            this.autocompleteInput.setAttribute('aria-expanded', 'true');
            this.optionsListDisplayed = true;
            const termDivs = document.querySelectorAll(`#${this.autocompleteInputId}-terms .nci-autocomplete__option`);
            termDivs.forEach((termDiv) => {
                termDiv.addEventListener('click', this.optionClickEventListener, true);
            });
        }
    }
    handleOptionClick(e) {
        const currOption = e.currentTarget;
        const currSpan = currOption.querySelector('span');
        const selectedVal = currSpan.getAttribute('aria-label') || '';
        this.selectedOptionInfo = {
            inputtedTextWhenSelected: this.autocompleteInput.value,
            selectedOptionValue: selectedVal,
            selectedOptionIndex: Number(currOption.getAttribute('aria-posinset')),
        };
        this.autocompleteInput.dispatchEvent(new CustomEvent('nci-autocomplete:optionSelected', {
            detail: this.selectedOptionInfo,
        }));
        this.autocompleteInput.value = selectedVal;
        this.autocompleteInput.focus();
        this.closeListbox();
    }
    updateAnnouncer(termCount) {
        if (termCount >= 1) {
            this.announcer.innerHTML =
                document.documentElement.lang === 'es'
                    ? `${termCount.toString()} sugerencias automáticas. Use flecha arriba o flecha abajo para escuchar las opciones.`
                    : `${termCount.toString()} suggestions found, use up and down arrows to review`;
        }
        else {
            this.announcer.innerHTML = '';
        }
    }
}
exports.NCIAutocomplete = NCIAutocomplete;
NCIAutocomplete.optionDefaults = {
    highlightMatchingText: true,
    maxOptionsCount: 10,
    minCharCount: 3,
    minPlaceholderMsg: '',
    listboxClasses: '',
    optionSetSize: null,
};
