module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-mdx@2.15.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx","components":"/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/component-page-layout.jsx","utility":"/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images"}],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/bryanp/Development/tmp/ncids-3.0.0/docs","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@3.14.0_gatsby@3.15.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5b0c1923f3d0f117a2f3a10a5b7648b2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
