import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Color sets the emotional tone of a user interface and is a powerful way to establish consistency. Each color, as well as the palette, is based off USWDS standards and has specific usage guidelines and role definitions to meet accessibility requirements.`}</p>
    <p>{`We organize our color tokens into State, Theme and System tokens, based on USWDS color token structure but modified for NCIDS. These changes are rooted in current NCI and NIH brand colors`}</p>
    <p>{`For how to apply colors in Figma, refer to our `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Figma Guide`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      