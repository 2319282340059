"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultMegaMenuSource = void 0;
class DefaultMegaMenuSource {
    getMegaMenuContent(id) {
        console.warn(`Default Mega Menu Adapter does not support setting data-menu-id properties. Cannot fetch ${id}`);
        return null;
    }
}
exports.DefaultMegaMenuSource = DefaultMegaMenuSource;
