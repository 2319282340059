"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FocusTrap = void 0;
class FocusTrap {
    constructor(element) {
        this.focusableContent = [];
        this.eventListener = (event) => this.checkTrap(event);
        this.element = element;
        this.focusableElements =
            "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])";
    }
    toggleTrap(state, context) {
        if (state) {
            this.findFocusableElements(context);
            context.addEventListener('keydown', this.eventListener, true);
        }
        else {
            context.removeEventListener('keydown', this.eventListener, true);
        }
    }
    findFocusableElements(element) {
        this.context = element;
        this.focusableContent = Array.from(element.querySelectorAll(this.focusableElements));
        this.firstFocusableElement = (element.querySelectorAll(this.focusableElements)[0]);
        this.lastFocusableElement = (this.focusableContent[this.focusableContent.length - 1]);
    }
    checkTrap(event) {
        const eventKey = event;
        const isTabPressed = eventKey.key === 'Tab' || parseInt(eventKey.code, 10) === 9;
        if (!isTabPressed) {
            return;
        }
        if (eventKey.shiftKey) {
            if (document.activeElement === this.firstFocusableElement) {
                this.lastFocusableElement.focus();
                eventKey.preventDefault();
            }
        }
        else {
            if (document.activeElement === this.lastFocusableElement) {
                this.firstFocusableElement.focus();
                eventKey.preventDefault();
            }
        }
    }
}
exports.FocusTrap = FocusTrap;
