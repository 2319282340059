import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The NCIDS uses the U.S. Web Design System (USWDS) spacing units out of the box from USWDS. Their spacing unit tokens are based on multiples of 8px, with additional tokens for small sizes, named tokens for large sizes, and a more limited selection of negative tokens.`}</p>
    <h2>{`Applying Spacing Units for Designers`}</h2>
    <p>{`For more on how to apply spacing units in Figma, refer to our `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Figma Guide`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      