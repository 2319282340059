"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USAAccordion = void 0;
class USAAccordion {
    constructor(accordionContainer, options) {
        this.accordionToggleClickEventListener = (event) => this.handleAccordionToggleClick(event);
        this.accordionContainer = accordionContainer;
        this.accordionContentIds = [];
        this.options = Object.assign(Object.assign({}, USAAccordion.optionDefaults), options);
        this.initialize();
    }
    static create(element, options) {
        return new this(element, options);
    }
    initialize() {
        this.updateDom();
    }
    updateDom() {
        var _a;
        if (this.options.allowMultipleOpen) {
            this.accordionContainer.classList.add('usa-accordion--multiselectable');
            this.accordionContainer.setAttribute('data-allow-multiple', '');
        }
        const proseContent = this.accordionContainer.classList.contains('usa-prose') ||
            ((_a = this.accordionContainer.firstElementChild) === null || _a === void 0 ? void 0 : _a.classList.contains('usa-prose'));
        const headings = this.accordionContainer.querySelectorAll('h1, h2, h3, h4, h5, h6');
        const accordionHeadingLvl = headings[0].tagName;
        const accordionHeadings = Array.from(headings).filter((heading) => {
            return heading.tagName == accordionHeadingLvl;
        });
        accordionHeadings.forEach((heading, index) => {
            const initOpen = this.options.openSections.includes(index + 1);
            if (heading.nextElementSibling &&
                heading.nextElementSibling.id &&
                !heading.nextElementSibling.classList.contains('usa-accordion')) {
                this.accordionContentIds.push(heading.nextElementSibling.id);
                const existingButton = heading.getElementsByClassName('usa-accordion__button')[0];
                existingButton.addEventListener('click', this.accordionToggleClickEventListener, true);
                existingButton.setAttribute('aria-expanded', initOpen ? 'true' : 'false');
                heading.nextElementSibling.hidden = !initOpen;
                return;
            }
            heading.classList.add('usa-accordion__heading');
            const accordionSectionId = this.generateUniqueId();
            this.accordionContentIds.push(accordionSectionId);
            const button = document.createElement('button');
            button.textContent = heading.textContent;
            button.classList.add('usa-accordion__button');
            button.setAttribute('aria-controls', accordionSectionId);
            button.setAttribute('type', 'button');
            button.setAttribute('aria-expanded', initOpen ? 'true' : 'false');
            button.addEventListener('click', this.accordionToggleClickEventListener, true);
            heading.textContent = '';
            heading.appendChild(button);
            const contentContainer = document.createElement('div');
            contentContainer.classList.add('usa-accordion__content');
            if (proseContent) {
                contentContainer.classList.add('usa-prose');
            }
            contentContainer.setAttribute('id', accordionSectionId);
            contentContainer.hidden = !initOpen;
            let nextSibling = heading.nextElementSibling;
            while (nextSibling && nextSibling.tagName !== accordionHeadingLvl) {
                contentContainer.appendChild(nextSibling);
                nextSibling = heading.nextElementSibling;
            }
            heading.insertAdjacentElement('afterend', contentContainer);
        });
    }
    unregister() {
        if (this.options.allowMultipleOpen) {
            this.accordionContainer.classList.remove('usa-accordion--multiselectable');
            delete this.accordionContainer.dataset.allowMultiple;
        }
        const accordionHeadings = Array.from(this.accordionContainer.querySelectorAll('.usa-accordion__heading'));
        accordionHeadings.forEach((heading) => {
            const button = heading.querySelector('button');
            button.removeEventListener('click', this.accordionToggleClickEventListener, true);
            heading.innerHTML = button.textContent;
            heading.classList.remove('usa-accordion__heading');
        });
        const accordionContents = Array.from(this.accordionContainer.querySelectorAll('.usa-accordion__content'));
        accordionContents.forEach((contentBlock) => {
            contentBlock.outerHTML = contentBlock.innerHTML;
        });
    }
    closeAll() {
        this.collapseAll();
        this.accordionContainer.dispatchEvent(new CustomEvent('usa-accordion:closeAll', {
            detail: this.accordionContainer,
        }));
    }
    openAll() {
        this.accordionContentIds.forEach((contentId) => {
            var _a;
            document.getElementById(contentId).hidden = false;
            (_a = document
                .querySelector(`[aria-controls=${contentId}]`)) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-expanded', 'true');
        });
        this.accordionContainer.dispatchEvent(new CustomEvent('usa-accordion:openAll', {
            detail: this.accordionContainer,
        }));
    }
    collapseAll() {
        this.accordionContentIds.forEach((contentId) => {
            var _a;
            document.getElementById(contentId).hidden = true;
            (_a = document
                .querySelector(`[aria-controls=${contentId}]`)) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-expanded', 'false');
        });
    }
    toggleSectionWithId(toggleId) {
        const element = document.getElementById(toggleId);
        if (element)
            element.hidden = !element.hidden;
    }
    generateUniqueId() {
        return 'acc' + Math.floor(1000 + Math.random() * 9000);
    }
    handleAccordionToggleClick(e) {
        const currBtn = e.currentTarget;
        const expanded = currBtn.getAttribute('aria-expanded') === 'true';
        const newState = expanded ? 'false' : 'true';
        if (newState === 'true' && !this.options.allowMultipleOpen) {
            this.closeAll();
        }
        currBtn.setAttribute('aria-expanded', newState);
        const contentTargetId = currBtn.getAttribute('aria-controls');
        const contentTarget = document.getElementById(contentTargetId);
        contentTarget.hidden = newState !== 'true';
        this.accordionContainer.dispatchEvent(new CustomEvent(`usa-accordion:section:${newState === 'true' ? 'expand' : 'collapse'}`, {
            detail: currBtn.textContent,
        }));
    }
    static autoInit() {
        document.addEventListener('DOMContentLoaded', () => {
            const accordions = Array.from(document.getElementsByClassName('usa-accordion'));
            accordions.forEach((element) => {
                const htmlElement = element;
                const isMultiSelect = htmlElement.hasAttribute('data-allow-multiple') ||
                    htmlElement.classList.contains('usa-accordion--multiselectable');
                let dataOpenSections = JSON.parse(`[${htmlElement.dataset.openSections || '1'}]`);
                if (dataOpenSections.length > 1 && isMultiSelect === false) {
                    dataOpenSections = this.optionDefaults.openSections;
                    console.warn('Multiple sections selected as open, but accordion is not enabled as multiselectable. ' +
                        'Displaying first section as open per the behavior of the default accordion. ' +
                        'Please use a multiselect accordion if you wish to have multiple open sections at once.');
                }
                return this.create(htmlElement, Object.assign(Object.assign({}, this.optionDefaults), { allowMultipleOpen: isMultiSelect, openSections: dataOpenSections }));
            });
        });
    }
    static createAll() {
        const accordions = Array.from(document.querySelectorAll('.usa-accordion'));
        const instances = accordions.map((element) => {
            const htmlElement = element;
            const isMultiSelect = htmlElement.hasAttribute('data-allow-multiple') ||
                htmlElement.classList.contains('usa-accordion--multiselectable');
            let dataOpenSections = JSON.parse(`[${htmlElement.dataset.openSections || '1'}]`);
            if (dataOpenSections.length > 1 && isMultiSelect === false) {
                dataOpenSections = this.optionDefaults.openSections;
                console.warn('Multiple sections selected as open, but accordion is not enabled as multiselectable. ' +
                    'Displaying first section as open per the behavior of the default accordion' +
                    'Please use a multiselect accordion if you wish to have multiple open sections at once.');
            }
            return this.create(htmlElement, Object.assign(Object.assign({}, this.optionDefaults), { allowMultipleOpen: isMultiSelect, openSections: dataOpenSections }));
        });
        return instances;
    }
}
exports.USAAccordion = USAAccordion;
USAAccordion.optionDefaults = {
    allowMultipleOpen: false,
    openSections: [1],
};
