import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About the NCI Design System`}</h1>
    <p>{`The NCI Design System (NCIDS) provides the building blocks to make cohesive, accessible, mobile-friendly websites and applications.`}</p>
    <h2>{`What is a design system?`}</h2>
    <p>{`A design system is a collection of reusable components and patterns, guided by clear standards for use, that can be assembled to build websites and web applications. Released as design toolkits and code snippets, design systems bring consistency and efficiency to digital properties within an organization. Design systems are always evolving to account for feedback from teams using the systems as well as changes in user experience patterns and best practices.`}</p>
    <h2>{`Our approach`}</h2>
    <p>{`NCIDS is an adaptation of the U.S. Web Design System (USWDS) created for NCI informational websites and applications to enhance their user experience, increase accessibility, and offer opportunities for efficiencies and consistency.`}</p>
    <h3>{`How NCIDS is part of NCI's digital strategy`}</h3>
    <p><strong parentName="p">{`NCIDS is an important component of NCI's digital strategy.`}</strong></p>
    <p>{`Work driven by NCI’s digital strategy follows these guiding principles:`}</p>
    <ul>
      <li parentName="ul">{`audience-focused`}</li>
      <li parentName="ul">{`data-informed`}</li>
      <li parentName="ul">{`collaborative`}</li>
      <li parentName="ul">{`agile`}</li>
      <li parentName="ul">{`accessible to all`}</li>
    </ul>
    <p>{`NCIDS embodies these principles and helps to make the digital strategy a reality for teams working to create a unified, audience-focused, and data-informed presence for NCI.`}</p>
    <p>{`NCI’s design system makes it easier for teams to build accessible and responsive digital products with consistent usability that provides a seamless, user-friendly experience. This is a key requirement of the 21st Century Integrated Digital Experience Act (IDEA), as outlined in `}<a parentName="p" {...{
        "href": "https://mynci.cancer.gov/topics/nci-digital-strategy"
      }}>{`NCI’s Digital Strategy, on the myNCI intranet`}</a>{`.`}</p>
    <p>{`NCIDS provides standardized design and user interface elements and patterns that follow usability best practices. This speeds up the development process and helps teams focus on creating compelling digital content.`}</p>
    <h3>{`Our design principles`}</h3>
    <p>{`Our design principles reflect the NCIDS design philosophy. We are committed to helping those who use our design system successfully adopt it, in order to keep the user experience of NCI consistent and seamless.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Visual style`}</strong>{` promotes consistency and one visual voice so users can find cancer related content efficiently.`}</li>
      <li parentName="ul"><strong parentName="li">{`Accessibility`}</strong>{` uses inclusive and representative visuals that engage all users.`}</li>
      <li parentName="ul"><strong parentName="li">{`User research`}</strong>{` builds empathy with users by testing often and gathering feedback.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reusable code`}</strong>{` simplifies development. Code is friendly, easy to use and accessible.`}</li>
      <li parentName="ul"><strong parentName="li">{`Governance`}</strong>{` provides a framework for maintenance by a dedicated team of designers, developers, information architects, and product managers.`}</li>
    </ul>
    <h3>{`How user research guides NCIDS`}</h3>
    <p>{`As an adaptation of USWDS, our user research builds on the foundational research conducted by USWDS, contributing to our design principles and the guiding principles outlined by NCI’s digital strategy. USWDS is focused on meeting the needs of the USWDS community and people with disabilities. `}<a parentName="p" {...{
        "href": "/about/research"
      }}>{`Learn more about how research supports USWDS decisions`}</a>{`.`}</p>
    <h2>{`Design resources`}</h2>
    <p>{`NCIDS provides a Figma community file for our components, templates, and tokens/utilities. By using the NCIDS design kit, you will automatically receive updates we make to our libraries, ensuring your designs stay up to date with the latest releases. `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Learn how to design with the NCIDS and download the NCIDS Figma Design Kit`}</a>{`.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`NCIDS is built as an extension to USWDS for the use on NCI web properties. As such, accessibility is a core design principle for NCIDS, used at each step of the development and design processes.`}</p>
    <p>{`NCIDS is subject to the requirements of Section 508 of the Rehabilitation Act of 1973, amended in 1998 to require federal agencies to make their electronic and information technology accessible to people with disabilities. Section 508 incorporates by reference the Web Content Accessibility Guidelines (WCAG) 2.0 Level AA success criteria. NCIDS and its components are designed to help websites comply with accessibility requirements.`}</p>
    <h2>{`Contact us`}</h2>
    <p>{`This website is managed by NCI's Office of Dissemination and Digital Communications.`}</p>
    <p>{`Interested in adopting NCIDS for your NCI website or web application? Please contact the OCPL digital team at `}<a parentName="p" {...{
        "href": "mailto:ncidigital@nih.gov?subject=NCI%20Design%20System%20Inquiry"
      }}>{`ncidigital@nih.gov`}</a>{` for more information. We can meet with individual teams for site-specific discussion and questions.`}</p>
    <p>{`For NCI staff to get in touch with us regarding suggestions or feedback, contact us via our `}<a parentName="p" {...{
        "href": "https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3AczPgjMLyJafICTCGz-n-NxlkmNF1tP1Ya6Eu2sZ1FTI1%40thread.tacv2%2Fconversations%3FgroupId%3De3158cae-7c76-436f-ba14-4a879d7695c5%26tenantId%3D14b77578-9773-42d5-8507-251ca2dc2b06&type=team&deeplinkId=557e274b-2436-4674-9bcc-1fecad79a457&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true"
      }}>{`NCI OCPL Web Design System Teams channel`}</a>{`.`}</p>
    <h2>{`Release notes`}</h2>
    <p>{`For detailed information on each NCIDS release, please see our `}<a parentName="p" {...{
        "href": "https://github.com/NCIOCPL/ncids/releases"
      }}>{`Release Page`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      