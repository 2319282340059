import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
import { FontFamilyTable, FontFamilyRow, FontSizeThemeTable, FontSizeThemeRow } from '../../../src/components/typography';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Set the font size and the font family together.`}</p>
    <h2>{`NCIDS Fonts`}</h2>
    <p>{`To use the following Google fonts in your site, add the follow block into your `}<inlineCode parentName="p">{`HEAD`}</inlineCode></p>
    <h3>{`Recommended Way to Use Fonts`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "nopreview",
        "nopreview": true
      }}>{`<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;
    0,600;1,300;1,400;1,600&family=Poppins:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600
    &family=Roboto+Mono:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap"
    rel="stylesheet"
/>
`}</code></pre>
    <p>{`The NCIDS/U.S. Web Design System (USWDS) provides design tokens and utilities to set font styles.`}</p>
    <p>{`To download fonts, refer to the `}<a parentName="p" {...{
        "href": "/foundations/typography"
      }}>{`Typography`}</a>{` page.`}</p>
    <h2>{`Font Family Tokens`}</h2>
    <p>{`The NCIDS uses the same font family tokens from the USWDS and contains Type and role-based tokens.`}</p>
    <p>{`Type-based tokens are based on the available fonts, e.g. poppins or open-sans.`}</p>
    <p>{`Role-based tokens use the type-based tokens and are based on the role the text will play. For example, button and other input text should use the ui role; web page content should use the body role.`}</p>
    <p>{`Role-based tokens should be used whenever possible. The idea is to group like elements so that if the font family is changed, all those elements of that role change. This helps prevent scenarios where you want to change all heading items, for example, and need to find and change all header instances in your code. It’s likely that you will miss some headings if you are manually finding and updating all instances.`}</p>
    <h3>{`Type-based Tokens`}</h3>
    <p>{`NOTE: NCIDS does not use all USWDS type-based tokens.`}</p>
    <FontFamilyTable mdxType="FontFamilyTable">
	<FontFamilyRow token="mono" setting="roboto-mono" variable="theme-font-type-mono" mdxType="FontFamilyRow" />
	<FontFamilyRow token="sans" setting="open-sans" variable="theme-font-type-sans" mdxType="FontFamilyRow" />
	<FontFamilyRow token="serif" setting="poppins" variable="theme-font-type-serif" mdxType="FontFamilyRow" />
    </FontFamilyTable>
    <h3>{`Role-based tokens`}</h3>
    <FontFamilyTable mdxType="FontFamilyTable">
	<FontFamilyRow token="alt" setting="serif" variable="theme-font-role-alt" mdxType="FontFamilyRow" />
	<FontFamilyRow token="body" setting="sans" variable="theme-font-role-body" mdxType="FontFamilyRow" />
	<FontFamilyRow token="code" setting="mono" variable="theme-font-role-mono" mdxType="FontFamilyRow" />
	<FontFamilyRow token="heading" setting="serif" variable="theme-font-role-heading" mdxType="FontFamilyRow" />
	<FontFamilyRow token="ui" setting="sans" variable="theme-font-role-ui" mdxType="FontFamilyRow" />
    </FontFamilyTable>
    <h2>{`Custom NCIDS Type Scale`}</h2>
    <FontSizeThemeTable mdxType="FontSizeThemeTable">
	<FontSizeThemeRow token="3xs" size="2" variable="theme-type-scale-3xs" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="2xs" size="3" variable="theme-type-scale-2xs" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="xs" size="5" variable="theme-type-scale-xs" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="sm" size="7" variable="theme-type-scale-sm" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="md" size="8" variable="theme-type-scale-md" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="lg" size="9" variable="theme-type-scale-lg" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="xl" size="10" variable="theme-type-scale-xl" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="2xl" size="11" variable="theme-type-scale-2xl" mdxType="FontSizeThemeRow" />
	<FontSizeThemeRow token="3xl" size="12" variable="theme-type-scale-3xl" mdxType="FontSizeThemeRow" />
    </FontSizeThemeTable>
    <h2>{`Using utilities mixins`}</h2>
    <ul>
      <li parentName="ul">{`Use single-quoted strings in utility mixins. Do not quote numbers or px values, with the exception of half `}<inlineCode parentName="li">{`(05)`}</inlineCode>{` values like `}<inlineCode parentName="li">{`'05'`}</inlineCode>{`, `}<inlineCode parentName="li">{`'105'`}</inlineCode>{`, and `}<inlineCode parentName="li">{`'205'`}</inlineCode>{` which should be treated as strings.`}</li>
      <li parentName="ul">{`String tokens for half values (05) and negative values (like `}<inlineCode parentName="li">{`neg-1`}</inlineCode>{`)may also be written with their unquoted number token equivalent: `}<inlineCode parentName="li">{`0.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`1.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`2.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`2.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`-1`}</inlineCode>{`, `}<inlineCode parentName="li">{`-1.5`}</inlineCode>{`, etc`}</li>
      <li parentName="ul">{`If multiple utilities share the same prefix (ex: `}<inlineCode parentName="li">{`.text-`}</inlineCode>{` or `}<inlineCode parentName="li">{`.flex-`}</inlineCode>{`) or a single utility accepts multiple kinds of values (ex: .border accepts both border weight and border color), their shared mixin can accept multiple comma-separated values: `}<inlineCode parentName="li">{`u-text('primary-darkest', 'no-underline', 'bold')`}</inlineCode>{` or `}<inlineCode parentName="li">{`u-border-top(2px, 'accent-warm')`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Output any utility mixin as !important by appending !important to the mixin’s argument list: `}<inlineCode parentName="li">{`u-bg('red-warm-50v', !important)`}</inlineCode>{`
Set the font weight using the u-text mixin or the font-weight function.`}</li>
    </ul>
    <p>{`To set font size and family in Figma, refer to the section “Using Our Tokens and Utilities” in our `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Figma Guide`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      