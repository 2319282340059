"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultMobileMenuSource = void 0;
class DefaultMobileMenuSource {
    constructor() {
        this.useUrlForNavigationId = true;
        this.lang = document.documentElement.lang;
        this.primaryNavItems = Array.from(document.querySelectorAll('.nci-header-nav__primary-item a'));
        this.items = [
            {
                id: 0,
                label: 'Error',
                path: '/',
                langcode: this.lang,
                hasChildren: false,
            },
        ];
    }
    getInitialMenuId() {
        return __awaiter(this, void 0, void 0, function* () {
            return 0;
        });
    }
    getNavigationLevel(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createDefaultMobileMenu(id);
        });
    }
    createDefaultMobileMenu(id) {
        this.items = this.primaryNavItems.map((item, index) => {
            const anchor = item;
            const path = anchor.href;
            const textContent = item.textContent;
            return {
                id: index,
                label: textContent,
                path: path,
                langcode: this.lang,
                hasChildren: false,
            };
        });
        return {
            id: id,
            label: '',
            path: '/',
            langcode: this.lang,
            hasChildren: true,
            items: this.items,
            parent: null,
        };
    }
}
exports.DefaultMobileMenuSource = DefaultMobileMenuSource;
