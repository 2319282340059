import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`3-Card Row`}</h1>
    <p>{`A 3-card row is a pattern of three cards united by a common theme, subject, or content type.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/4FeMfprVErO6esR8FLczp5/NCIDS-Design-Kit?type=design&node-id=5280%3A38952&mode=design&t=lGwrxbWFW3JAwAet-1"
      }}>{`View in Figma`}</a></p>
    <h2>{`Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ecda88df3ca9f08464a14989d1932e76/44af6/card-row_pattern.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.963190184049076%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB6NtMChwf/8QAGBAAAwEBAAAAAAAAAAAAAAAAAhESEBP/2gAIAQEAAQUCHpbNBa3/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAABEQAgIjGh/9oACAEBAAY/Ai9OHkyLp//EABwQAQABBAMAAAAAAAAAAAAAAAERABAhYUFRcf/aAAgBAQABPyEbYjypdLktEOJzhOrcRb//2gAMAwEAAgADAAAAEFMP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EKV//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/EIF//8QAHBABAAMAAgMAAAAAAAAAAAAAAQARIUGBYXGh/9oACAEBAAE/ENhwhs9OYUMCgA08/SWABoKrgdTButY9EFon/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a three card row.",
            "title": "Example of a three card row.",
            "src": "/static/ecda88df3ca9f08464a14989d1932e76/6aca1/card-row_pattern.jpg",
            "srcSet": ["/static/ecda88df3ca9f08464a14989d1932e76/d2f63/card-row_pattern.jpg 163w", "/static/ecda88df3ca9f08464a14989d1932e76/c989d/card-row_pattern.jpg 325w", "/static/ecda88df3ca9f08464a14989d1932e76/6aca1/card-row_pattern.jpg 650w", "/static/ecda88df3ca9f08464a14989d1932e76/7c09c/card-row_pattern.jpg 975w", "/static/ecda88df3ca9f08464a14989d1932e76/01ab0/card-row_pattern.jpg 1300w", "/static/ecda88df3ca9f08464a14989d1932e76/44af6/card-row_pattern.jpg 2110w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It is recommended that a 3-card row be composed of three ‘Cards’. Refer to `}<a parentName="p" {...{
        "href": "/components/card"
      }}>{`Card`}</a>{` for specific attributes of the card component.`}</p>
    <h2>{`Usage`}</h2>
    <ul>
      <li parentName="ul">{`Use heading to introduce content within the cards and to provide context for the association between cards in the 3-card row.`}</li>
    </ul>
    <h3>{`Where to use`}</h3>
    <ul>
      <li parentName="ul">{`Use this component patten on home and landing pages.`}
        <ul parentName="li">
          <li parentName="ul">{`Using one or two card components on a home or landing page is not recommended to maintain the visual consistency of the site.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`When to use`}</h3>
    <ul>
      <li parentName="ul">{`Use three cards in a row on your home or landing pages to showcase multiple content items such as articles, blogs, news, or events— while also highlighting the association between these content items.`}</li>
      <li parentName="ul">{`Refer to the card component page for alternative component guidance.`}</li>
    </ul>
    <h2>{`Best Practices`}</h2>
    <p>{`Refer to `}<a parentName="p" {...{
        "href": "/components/card"
      }}>{`Card`}</a>{` for best practice recommendations specific to the card.`}</p>
    <h3>{`Character limits`}</h3>
    <p>{`Character limits are strongly recommended to ensure text is concise, scannable, and not visually distorted.`}</p>
    <p><em parentName="p">{`Character limits are based on best practices and when a line of text will be forced to wrap onto a second line of text on desktop.`}</em></p>
    <ul>
      <li parentName="ul">{`3-card row heading: 40 characters`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use unordered lists and list items.`}</strong>{` Use a `}<inlineCode parentName="li">{`<ul>`}</inlineCode>{` for a card group and an `}<inlineCode parentName="li">{`<li>`}</inlineCode>{` for each card. This formatting allows screen readers to enumerate the items in the card group and allows shortcuts between list items.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use the appropriate heading level for your page.`}</strong>{` Update heading level based on the content of your page to make sure card headings are in the correct, logical outline order.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use CSS to order the media element.`}</strong>{` Logically, the media element should follow the header element. Don’t re-organize the markup to reverse their order.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container margin-y-3">
    <ul class="nci-card-group">
        <li class="nci-card desktop:grid-col-4">
            <a href="https://www.cancer.gov" aria-label="Card">
                <picture class="nci-card__image">
                    <source
                        media="(min-width: 1024px)"
                        srcset="../feature-card-images/news1-4x3.jpg"
                    />
                    <img src="../feature-card-images/news1-16x9.jpg" alt="News Image 1" />
                </picture>
                <div class="nci-card__body">
                    <span class="nci-card__title">CRCHD Diversity Training</span>
                    <p class="nci-card__description">
                        Treatment for severe COVID-19 with interferons decreased the viral
                        load of SARS-CoV-2, a new study found.
                    </p>
                </div>
            </a>
        </li>
        <li class="nci-card desktop:grid-col-4">
            <a href="https://www.cancer.gov" aria-label="Card">
                <picture class="nci-card__image">
                    <source
                        media="(min-width: 1024px)"
                        srcset="../feature-card-images/news2-4x3.jpg"
                    />
                    <img src="../feature-card-images/news2-16x9.jpg" alt="News Image 2" />
                </picture>
                <div class="nci-card__body">
                    <span class="nci-card__title">
                        Grant Application Development, Submission, Review, & Award
                    </span>
                    <p class="nci-card__description">
                        In patients with certain variations in the OAS1 gene, treatment for
                        severe COVID-19 with interferons decreased the viral load of
                        SARS-CoV-2, a new study found.
                    </p>
                </div>
            </a>
        </li>
        <li class="nci-card desktop:grid-col-4">
            <a href="https://www.cancer.gov" aria-label="Card">
                <picture class="nci-card__image">
                    <source
                        media="(min-width: 1024px)"
                        srcset="../feature-card-images/news3-4x3.jpg"
                    />
                    <img src="../feature-card-images/news3-16x9.jpg" alt="News Image 3" />
                </picture>
                <div class="nci-card__body">
                    <span class="nci-card__title">Capacitación en diversidad CRCHD</span>
                    <p class="nci-card__description">
                        El tratamiento para la COVID-19 grave con interferones disminuyó la
                        carga viral del SARS-CoV-2, según encontró un nuevo estudio.
                    </p>
                </div>
            </a>
        </li>
    </ul>
</div>
`}</code></pre>
    <h2>{`Package`}</h2>
    <p>{`Import this Sass partial into your stylesheet`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "nopreview",
        "nopreview": true
      }}>{`# Single Card Component
@forward "nci-card";

# Card Group Component
@forward "nci-card-group";
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      