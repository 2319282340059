import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Guide Card Row`}</h1>
    <p>{`The Guide Card component can be assembled into a pattern of two or three guide cards, each guide card providing a set of 2-6 links to related resources.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/4FeMfprVErO6esR8FLczp5/NCIDS-Design-Kit?type=design&node-id=5321%3A7481&mode=design&t=lGwrxbWFW3JAwAet-1"
      }}>{`View in Figma`}</a></p>
    <h2>{`Overview`}</h2>
    <p>{`The 2 guide card row pattern is composed of 2 guide cards with image and description.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/684e30fe758ff6664fcbab81b81cb0f4/ecc9d/guide-card-image-description_pattern.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.25153374233128%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6V9pSjQoAH//xAAYEAEAAwEAAAAAAAAAAAAAAAARAQISIP/aAAgBAQABBQLU6WtU6//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABoQAAICAwAAAAAAAAAAAAAAAAABESECIDH/2gAIAQEABj8CyUMmy+7/AP/EABsQAAIDAAMAAAAAAAAAAAAAAAFBABEhIDHB/9oACAEBAAE/ISqHWHYDoaIY8jG2NRwi+P8A/9oADAMBAAIAAwAAABB0Bzz/xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAwEBPxBj/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAQEBAAIDAAAAAAAAAAAAAREAMSFhQVHw/9oACAEBAAE/EFgAm2H28/umMxUFxwEkbhAvr1mA95BQrkSDpufLu93/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a two guide card row with image and description.",
            "title": "Example of a two guide card row with image and description.",
            "src": "/static/684e30fe758ff6664fcbab81b81cb0f4/6aca1/guide-card-image-description_pattern.jpg",
            "srcSet": ["/static/684e30fe758ff6664fcbab81b81cb0f4/d2f63/guide-card-image-description_pattern.jpg 163w", "/static/684e30fe758ff6664fcbab81b81cb0f4/c989d/guide-card-image-description_pattern.jpg 325w", "/static/684e30fe758ff6664fcbab81b81cb0f4/6aca1/guide-card-image-description_pattern.jpg 650w", "/static/684e30fe758ff6664fcbab81b81cb0f4/7c09c/guide-card-image-description_pattern.jpg 975w", "/static/684e30fe758ff6664fcbab81b81cb0f4/01ab0/guide-card-image-description_pattern.jpg 1300w", "/static/684e30fe758ff6664fcbab81b81cb0f4/ecc9d/guide-card-image-description_pattern.jpg 2112w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The 3 guide card row pattern is composed of 3 guide cards without image or description.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/22c9e44372aa90c5c506b38b68b7ebb6/ccd6b/guide-card-row_pattern.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.34969325153374%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHtrUypf//EABUQAQEAAAAAAAAAAAAAAAAAACBB/9oACAEBAAEFAqf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAACAwEAAAAAAAAAAAAAAAABEQAQITH/2gAIAQEAAT8hUIowc2nX/9oADAMBAAIAAwAAABBHD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAICAwEBAAAAAAAAAAAAAAERADEhQWFRcf/aAAgBAQABPxAB6qoQsvTEZMHyPazCZsCE8A+T/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a three guide card row without image or description.",
            "title": "Example of a three guide card row without image or description.",
            "src": "/static/22c9e44372aa90c5c506b38b68b7ebb6/6aca1/guide-card-row_pattern.jpg",
            "srcSet": ["/static/22c9e44372aa90c5c506b38b68b7ebb6/d2f63/guide-card-row_pattern.jpg 163w", "/static/22c9e44372aa90c5c506b38b68b7ebb6/c989d/guide-card-row_pattern.jpg 325w", "/static/22c9e44372aa90c5c506b38b68b7ebb6/6aca1/guide-card-row_pattern.jpg 650w", "/static/22c9e44372aa90c5c506b38b68b7ebb6/7c09c/guide-card-row_pattern.jpg 975w", "/static/22c9e44372aa90c5c506b38b68b7ebb6/01ab0/guide-card-row_pattern.jpg 1300w", "/static/22c9e44372aa90c5c506b38b68b7ebb6/ccd6b/guide-card-row_pattern.jpg 2108w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Refer to `}<a parentName="p" {...{
        "href": "/components/guide-card"
      }}>{`Guide Card`}</a>{` for specific attributes of the guide card component.`}</p>
    <h2>{`Usage`}</h2>
    <h3>{`Where to use`}</h3>
    <ul>
      <li parentName="ul">{`Use these component patterns on home and landing pages.`}
        <ul parentName="li">
          <li parentName="ul">{`Using 1 guide card component (with or without image and description) on a home or landing page is `}<strong parentName="li">{`not recommended`}</strong>{` to maintain the visual consistency of the site.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`When to use`}</h3>
    <ul>
      <li parentName="ul">{`Use the 2 guide card row pattern within your home or landing pages to showcase two sets of 2-6 links to related resources.`}
        <ul parentName="li">
          <li parentName="ul">{`We recommend grouping related links together within a guide card. However, the guide cards within this pattern do not have to be associated thematically.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Use the 3 guide card row pattern within your home or landing pages to showcase three sets of 2-6 links to related resources.`}
        <ul parentName="li">
          <li parentName="ul">{`We recommend grouping related links together within a guide card. However, all three guide cards do not have to be associated thematically to be a part of this pattern.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`When to consider something else`}</h3>
    <ul>
      <li parentName="ul">{`Showcasing call-to-actions (CTAs) for major initiatives or content marketing items that should be described further through an image and description.`}
        <ul parentName="li">
          <li parentName="ul">{`Alternative: `}<a parentName="li" {...{
              "href": "/components/promo-block"
            }}>{`Promo block`}</a>{`, promoting content for major initiatives will stand out better as a single item with an image and description using a promo block.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Showcasing multiple content items such as articles, blogs, news, or events— while also highlighting the association between these content items.`}
        <ul parentName="li">
          <li parentName="ul">{`Alternative: `}<a parentName="li" {...{
              "href": "/templates/3-card-row"
            }}>{`3-Card Row`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Showcasing one primary CTA that should be more prominently displayed than all other information on the page.`}
        <ul parentName="li">
          <li parentName="ul">{`Alternative: `}<a parentName="li" {...{
              "href": "/components/hero"
            }}>{`Hero with CTA Button`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`Best Practices`}</h2>
    <p>{`Refer to `}<a parentName="p" {...{
        "href": "/components/guide-card"
      }}>{`Guide Card`}</a>{` for best practice recommendations specific to the guide card.`}</p>
    <h2>{`Component Preview`}</h2>
    <h3>{`Guide card with image and description`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="nci-title-aligned-card-group">
    <h2 class="nci-guide-card__header">
        Patients and Caregivers, with a Title Long Enough to Wrap to a Second Line
    </h2>
    <div class="nci-guide-card nci-guide-card--with-image-and-description">
        <div class="nci-guide-card__wrapper">
            <picture class="nci-guide-card__image">
                <img src="../16x9_placeholder.png" alt="Placeholder Image" />
            </picture>
            <div class="nci-guide-card__body">
                <p class="nci-guide-card__description">
                    NCI is the nation's trusted source for cancer information. We're here
                    with information about causes and risk factors, early detection and
                    diagnosis, and treatment options.
                </p>
                <ul class="nci-card__button-group">
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Funding Opportunities</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Cancer Moonshot Funding Opportunities</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Funding Strategy</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Research Program Contacts</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Key NCI Initiatives</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Find a Clinical Trial</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <h2 class="nci-guide-card__header">Researchers</h2>
    <div class="nci-guide-card nci-guide-card--with-image-and-description">
        <div class="nci-guide-card__wrapper">
            <picture class="nci-guide-card__image">
                <img src="../16x9_placeholder.png" alt="Placeholder Image" />
            </picture>
            <div class="nci-guide-card__body">
                <p class="nci-guide-card__description">
                    Support for the best science underpins everything NCI does. NCI
                    supports the best scientists and research projects through a rigorous
                    grant application and peer review process.
                </p>
                <ul class="nci-card__button-group">
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Apply for a Grant</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Manage Your Award</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Grant Policies</a
                        >
                    </li>
                    <li>
                        <a
                            href="#!"
                            class="usa-button usa-button--outline usa-button--secondary"
                            >Grants and Management Contacts</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
`}</code></pre>
    <h3>{`Guide card without image and description`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container">
    <h2 class="nci-guide-card__header">
        Your Guide to NCI Grants &amp; Training
    </h2>

    <ul class="nci-card-group">
        <li class="nci-guide-card desktop:grid-col-4">
            <div class="nci-guide-card__wrapper">
                <div class="nci-guide-card__body">
                    <h2 class="nci-guide-card__title">Research Grant Funding</h2>
                    <ul class="nci-card__button-group">
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Funding Opportunities</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Cancer Moonshot Funding Opportunities</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Funding Strategy</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Research Program Contacts</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Grant Policies</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Grants and Management Contacts</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </li>

        <li class="nci-guide-card desktop:grid-col-4">
            <div class="nci-guide-card__wrapper">
                <div class="nci-guide-card__body">
                    <h2 class="nci-guide-card__title">Grants Process</h2>
                    <ul class="nci-card__button-group">
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Apply for a Grant</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Manage Your Award</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </li>

        <li class="nci-guide-card desktop:grid-col-4">
            <div class="nci-guide-card__wrapper">
                <div class="nci-guide-card__body">
                    <h2 class="nci-guide-card__title">Funding for Training</h2>
                    <ul class="nci-card__button-group">
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Funding for Cancer Training</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Research Training at NCI</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Building a Diverse Workforce</a
                            >
                        </li>
                        <li>
                            <a
                                href="#!"
                                class="usa-button usa-button--outline usa-button--secondary"
                                >Training Program Contacts</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</div>
`}</code></pre>
    <h2>{`Package`}</h2>
    <p>{`Import this Sass partial into your stylesheet`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "nopreview",
        "nopreview": true
      }}>{`@forward "nci-guide-card";
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      