import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The appearance of an item can be set by the following tokens and utilities.`}</p>
    <a href="/foundations/appearance/border" className="header-styled-link">
	Border
    </a>
    <p>{`Set width, color, style, and radius of an item's borders.`}</p>
    <a href="/foundations/appearance/outline" className="header-styled-link">
	Outline
    </a>
    <p>{`Set width and color of an item's outline.`}</p>
    <a href="/foundations/appearance/shadow" className="header-styled-link">
	Shadow
    </a>
    <p>{`Set size of an item's drop shadow.`}</p>
    <a href="/foundations/appearance/opacity" className="header-styled-link">
	Opacity
    </a>
    <p>{`Opacity tokens set the opacity (or transparency) of an item in increments of 10%.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      