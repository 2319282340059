import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started for Developers`}</h1>
    <h2>{`Step-by-step`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#phase-1-install"
        }}>{`Phase 1: Install`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#phase-2-compile"
        }}>{`Phase 2: Compile`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#phase-3-customize"
        }}>{`Phase 3: Customize`}</a></li>
    </ol>
    <h2>{`Phase 1: Install`}</h2>
    <p>{`The NCI Design System distributes our source code through a node package manager (npm), that uses a flavor of JavaScript called Node.js. The best way to add the Design System to your project is with npm via a Terminal window (check out some tips for using Terminal on a `}<a parentName="p" {...{
        "href": "https://support.apple.com/guide/terminal/open-or-quit-terminal-apd5265185d-f365-44cb-8b09-71a064a42125/mac"
      }}>{`Mac`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/p/windows-terminal/9n0dx20hk701?rtc=1&activetab=pivot:overviewtab"
      }}>{`Windows`}</a>{`).`}</p>
    <p>{`Installing the Design System with Node and npm not only allows you to install all the code the Design System needs to compile with just a couple commands, but will version any installed packages, like NCIDS, as well — meaning your project code is tied to a specific version of the Design System. Confirming what version you’re using and updating to a newer (or older) version is straightforward.`}</p>
    <p>{`To download fonts, refer to the `}<a parentName="p" {...{
        "href": "/foundations/typography"
      }}>{`Typography`}</a>{` page.`}</p>
    <h3>{`Step 1: Install Node and npm`}</h3>
    <p>{`Ensure that you have Node.js installed, preferably Node 18, to meet the required runtime environment.`}</p>
    <p>{`Open your Terminal application and a Terminal window. Check to see if you have Node installed with `}<inlineCode parentName="p">{`node -v`}</inlineCode>{`.`}</p>
    <p>{`If you don’t have Node, we recommend installing it through a node version manager such as `}<a parentName="p" {...{
        "href": "https://github.com/nvm-sh/nvm"
      }}>{`NVM`}</a>{` on a Mac or Linux machine, or `}<a parentName="p" {...{
        "href": "https://github.com/coreybutler/nvm-windows"
      }}>{`nvm-windows`}</a>{` on a Windows machine. It can also be installed directly from `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`Node.js`}</a>{`.`}</p>
    <h3>{`Step 2: Authenticating to the NCIDS npm registry`}</h3>
    <p>{`NCIDS utilizes GitHub’s npm registry to host its packages. To access and download these packages, you need to be authenticated with a valid access token.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Create your personal access token`}</strong>{`
Follow the steps outlined in GitHub’s documentation for `}<a parentName="p" {...{
            "href": "https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens#creating-a-personal-access-token-classic"
          }}>{`creating a personal access token (classic)`}</a>{`.
Ensure that, at a minimum, the required scopes are set to `}<inlineCode parentName="p">{`read:packages`}</inlineCode>{`:
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "61.34969325153374%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABiElEQVR42o2T2VLDMAxF+0rb7Ha8JnGdpaQFWobl///sMhLQoQsMD3ecaJRjXUlZVKqBDxOSUmGd1yzd9NiMe4Rhh66fEYYZ/fSASnmsM4GkqL+krrSQpsP89Io23qNUHqtCMmTaHTHcP/HZbV/QTK9wwzNMPMDEI3QzQrkNTNOjkI4LYaCwEeN8gN9sIWyLQjvUNsC2A9JSYZlWWKYCd6nAMpVISoO0skhLzYDv8wRMivPAKpN8KwHp+bMNkrXKBNan2Lm+GYvLIEG0jwzMhUVZNyhrj1w6rmadqy/dhi4um0pAGlKcHkD9JfvKReS6R6ZHFHaLVDRI8vOh/AmsbcfDIOuUSJvQxJklTAC3Kb+eMlu+Faxqj/nxBa4beSBk2YWJRXC69LKyX4GULE3LK0MfU1IhPe+m8pHtf/bynxWyZRexO7yfKixqD9uODNW8ASMq3V5Bb1vOJIRuEcY9V0RJNG1hOgjdoVItpAnchn9bVjZgf3xDVhl+L/n33J7JdhOkDbxOP4Efn8Qud4uWt58AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Screenshot showing read:packages checkbox",
              "title": "Screenshot showing read:packages checkbox",
              "src": "/static/4cf269983ab87124d4faf073f251ed35/a6d36/github-token-settings.png",
              "srcSet": ["/static/4cf269983ab87124d4faf073f251ed35/222b7/github-token-settings.png 163w", "/static/4cf269983ab87124d4faf073f251ed35/ff46a/github-token-settings.png 325w", "/static/4cf269983ab87124d4faf073f251ed35/a6d36/github-token-settings.png 650w", "/static/4cf269983ab87124d4faf073f251ed35/e548f/github-token-settings.png 975w", "/static/4cf269983ab87124d4faf073f251ed35/3c492/github-token-settings.png 1300w", "/static/4cf269983ab87124d4faf073f251ed35/97755/github-token-settings.png 2536w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Log in to the @NCIOCPL Organizational Scope on npm`}</strong>{`
If you are using npm 9+, run the following command to log in to npm with the organizational scope and using the GitHub npm registry:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`npm login --scope=@NCIOCPL --auth-type=legacy --registry=https://npm.pkg.github.com
`}</code></pre>
        <p parentName="li">{`You will be prompted to enter your username and password. Please be sure to use your new classic personal access token as your password:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`> Username: <USERNAME>
> Password: <TOKEN>
`}</code></pre>
        <p parentName="li">{`If you are using npm less than 9, login without the legacy option:`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`npm login --scope=@NCIOCPL --registry=https://npm.pkg.github.com
`}</code></pre>
        <p parentName="li">{`And follow the prompts to enter your email and password. Enter your token as your password.`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`> Email: <USERNAME>@users.noreply.github.com
> Password: <TOKEN>
`}</code></pre>
      </li>
    </ol>
    <p>{`For more details, see `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/packages/working-with-a-github-packages-registry/working-with-the-npm-registry#authenticating-with-a-personal-access-token"
      }}>{`authenticating with a personal access token `}</a>{`.`}</p>
    <h3>{`Step 3: Initialize your project in Node`}</h3>
    <p>{`Once you have Node and npm installed, go to the root of your project directory in Terminal. The root is the topmost directory associated with your project, the directory that includes all your project files and directories. In Terminal, the root will read as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd path/to/project/root
`}</code></pre>
    <p>{`Initialize your project to create a file called package.json. Once you have this file, you can use npm to install software (or packages) like NCIDS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm init
`}</code></pre>
    <p>{`This initialization will start a series of prompts at the command line. Usually the defaults (which are noted in parentheses) are okay for a simple project. You can always edit these values later.`}</p>
    <p>{`In order to download the NCIDS, you will have to set up the @nciocpl organizational scope in npm to see the package. Create a `}<inlineCode parentName="p">{`.npmrc`}</inlineCode>{` file in the root of your project containing the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`@nciocpl:registry=https://npm.pkg.github.com
`}</code></pre>
    <h3>{`Step 4: Install NCIDS`}</h3>
    <p>{`Now, you can install NCIDS into your project from the command line with npm.`}</p>
    <p>{`At minimum, your project will require the `}<inlineCode parentName="p">{`ncids-css`}</inlineCode>{` package. Install it via the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @nciocpl/ncids-css
`}</code></pre>
    <p>{`In order to use the javascript components, download the `}<inlineCode parentName="p">{`ncids-js`}</inlineCode>{` package separately. Install it via the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @nciocpl/ncids-js
`}</code></pre>
    <p><inlineCode parentName="p">{`Don’t modify the source code. Now that you’ve installed the NCIDS source code, it is controlled by npm and could be rewritten at any time.`}</inlineCode></p>
    <h2>{`Phase 2: Compile`}</h2>
    <p>{`NCIDS Sass needs three things to compile properly:`}</p>
    <ul>
      <li parentName="ul">{`Sass Module syntax: NCIDS requires a modern Sass compiler that can parse Sass Module syntax.`}</li>
      <li parentName="ul">{`Autoprefixing: NCIDS requires Autoprefixing your Cascading Style Sheets (CSS) with a specific .browserslistrc.`}</li>
      <li parentName="ul">{`Sass Load Paths: NCIDS requires Sass compilers use Load Paths that reference the /packages directory in the U.S. Web Design System (USWDS) package.`}</li>
    </ul>
    <h3>{`Autoprefixing`}</h3>
    <p>{`The design system requires autoprefixing to work properly. Don't add vendor prefixes to your custom styles manually — it is more reliable to use autoprefixing.`}</p>
    <p>{`We use the following autoprefixer settings via .browserslistrc config:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`> 2%
last 2 versions
not IE 11
not dead
`}</code></pre>
    <h4>{`About Sass Load Paths`}</h4>
    <p><inlineCode parentName="p">{`ncids-css`}</inlineCode>{` requires `}<a parentName="p" {...{
        "href": "https://sass-lang.com/documentation/at-rules/use#load-paths"
      }}>{`Sass Load Paths`}</a>{` to compile for use in your project.`}</p>
    <p>{`Load paths must include a path to the `}<inlineCode parentName="p">{`/packages`}</inlineCode>{` directory for NCIDS packages and `}<inlineCode parentName="p">{`/uswds-packages`}</inlineCode>{` for USWDS packages. An example using Webpack might look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
        loader: 'sass-loader',
        options: {
            sassOptions: {
                includePaths: [
                    path.join(
                        __dirname,
                        './node_modules/@nciocpl/ncids-css/packages'
                    ),
                    path.join(
                        __dirname,
                        './node_modules/@nciocpl/ncids-css/uswds-packages'
                    ),
                ],
            },
        },
},
`}</code></pre>
    <h3>{`Import NCIDS styles to your project`}</h3>
    <ol>
      <li parentName="ol">{`Create a sass stylesheet in your project.`}</li>
    </ol>
    <p>{`Import the required sass partials:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sass"
      }}>{`@use 'uswds-core' with (
    $theme-image-path: '@nciocpl/ncids-css/uswds-img',
);
@forward 'uswds-global';
`}</code></pre>
    <p>{`Next, add the entire NCIDS package library or just the packages you need:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Option 1: Import only the packages you need. (recommended)`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sass"
          }}>{`@forward 'nci-header';
@forward 'usa-breadcrumbs';
@forward 'usa-footer';
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Option 2: Import the entire ncids-css library. `}<strong parentName="p"><em parentName="strong">{`This is not recommended due to the file size that will be generated.`}</em>{`*`}</strong></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-sass"
          }}>{`@forward 'ncids';
`}</code></pre>
      </li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Create an index.js file. This is the entrypoint file set in the compiler of your project.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import './styles.scss';

console.log('hello world');
`}</code></pre>
    <h4>{`About `}<inlineCode parentName="h4">{`@use`}</inlineCode>{` and `}<inlineCode parentName="h4">{`@forward`}</inlineCode></h4>
    <p>{`The @use and @forward directives are used to import and manage Sassy Cascading Style Sheets (SCSS) modules and partials.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`@use`}</inlineCode>{` directive allows you to import and use functionality from other SCSS modules.
It provides better encapsulation and avoids conflicting global CSS class names.
Use it to import SCSS modules that contain reusable styles, mixins, variables, or functions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`@forward`}</inlineCode>{` directive allows you to re-export styles from one module to another.
It acts as a bridge between different SCSS modules, making styles available for import in other files.
Use it when you want to expose certain styles or functionality from one module to be used in another.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` The load paths option for importing Sass partials or modules is specific to `}<strong parentName="p">{`Dart Sass`}</strong>{`. If you're using another Sass implementation, such as Node Sass, LibSass, or Ruby Sass, the load paths option may not be available. Make sure to use the appropriate load path mechanism specific to your chosen Sass implementation.`}</p>
    <h3>{`Examples`}</h3>
    <p>{`For more detailed instructions on how to setup your project, visit our `}<a parentName="p" {...{
        "href": "https://github.com/NCIOCPL/ncids-example-sites"
      }}>{`example app repository`}</a>{`.`}</p>
    <h2>{`Phase 3: Customize`}</h2>
    <p>{`Customize the design system with settings and custom code.`}</p>
    <p>{`The USWDS `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/documentation/settings/"
      }}>{`Settings`}</a>{` page describes each of the settings available in the settings files, as well as the values the settings accept. Most settings accept design tokens, visit the `}<a parentName="p" {...{
        "href": "/foundations"
      }}>{`Foundations`}</a>{` section of our website for more information on the available tokens for color, spacing units, font size, and more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      