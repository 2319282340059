import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/component-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`NCI Design System (NCIDS) is built as an extension to the USWDS. In addition to its own components, NCIDS includes existing USWDS components for use within NCI web properties. USWDS-specific components can be found directly on the `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/components/overview/"
      }}>{`USWDS Components page`}</a>{` and are also listed below.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/accordion/"
        }}>{`Accordion`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/alert/"
        }}>{`Alert`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/button-group/"
        }}>{`Button group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/character-count/"
        }}>{`Character count`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/checkbox/"
        }}>{`Checkbox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/collection/"
        }}>{`Collection`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/combo-box/"
        }}>{`Combo box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/date-picker/"
        }}>{`Date picker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/date-range-picker/"
        }}>{`Date range picker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/file-input/"
        }}>{`File input`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/form/"
        }}>{`Form`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/icon/"
        }}>{`Icon`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/icon-list/"
        }}>{`Icon list`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/identifier/"
        }}>{`Identifier`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/in-page-navigation/"
        }}>{`In-page navigation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/input-prefix-suffix/"
        }}>{`Input prefix or suffix`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/input-mask/"
        }}>{`Input mask`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/link/"
        }}>{`Link`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/memorable-date/"
        }}>{`Memorable date`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/modal/"
        }}>{`Modal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/pagination/"
        }}>{`Pagination`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/process-list/"
        }}>{`Process list`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/prose/"
        }}>{`Prose`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/radio-buttons/"
        }}>{`Radio buttons`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/range-slider/"
        }}>{`Range slider`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/search/"
        }}>{`Search`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/select/"
        }}>{`Select`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/step-indicator/"
        }}>{`Step indicator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/summary-box/"
        }}>{`Summary box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/table/"
        }}>{`Table`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/tag/"
        }}>{`Tag`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/tooltip/"
        }}>{`Tooltip`}</a></li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <p>{`View the accessibility guidelines for each component on their respective pages on the `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/components/overview/"
      }}>{`USWDS website`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      