import React from 'react';

const Error404Page = () => {
	return (
		<div className="usa-section padding-2">
			<h1>404 Page not found</h1>
		</div>
	);
};

export default Error404Page;
