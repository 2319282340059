import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The spacing of an item can be set by the following tokens and utilities.`}</p>
    <a href="/foundations/spacing/spacing-unit-tokens" className="header-styled-link">
	Spacing Unit Tokens
    </a>
    <p>{`The spacing unit tokens.`}</p>
    <a href="/foundations/spacing/height-width" className="header-styled-link">
	Height and Width
    </a>
    <p>{`Set width and height of an item.`}</p>
    <a href="/foundations/spacing/margin-padding" className="header-styled-link">
	Margin and Padding
    </a>
    <p>{`Set margin and padding of an item.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      