import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/component-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Components are reusable elements and features used to meet common user-interface needs. NCIDS components are designed to work together to create patterns, templates, and other user-friendly experiences.`}</p>
    <p>{`To learn more about how these components can be arranged together to meet specific user needs, visit `}<a parentName="p" {...{
        "href": "/templates"
      }}>{`Templates`}</a>{`.`}</p>
    <p>{`Any components listed with “USWDS" are from the U.S. Web Design System.`}</p>
    <h2>{`Actions`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/button"
        }}>{`Button`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/button-group/"
        }}>{`Button group`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skipnav"
        }}>{`Skipnav`}</a></li>
    </ul>
    <h2>{`Content Display`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/accordion/"
        }}>{`Accordion`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/cta-strip"
        }}>{`Call-to-action (CTA) strip`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/card"
        }}>{`Card`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/collection/"
        }}>{`Collection`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/guide-card"
        }}>{`Guide card`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/hero"
        }}>{`Hero`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/icon/"
        }}>{`Icon`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/icon-list/"
        }}>{`Icon list`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/list"
        }}>{`List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/promo-block"
        }}>{`Promo block`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/prose/"
        }}>{`Prose`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/summary-box"
        }}>{`Summary box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/table/"
        }}>{`Table`}</a>{` (USWDS)`}</li>
    </ul>
    <h2>{`Forms, Input, and Selection`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/autocomplete"
        }}>{`Autocomplete`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/character-count/"
        }}>{`Character count`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/checkbox/"
        }}>{`Checkbox`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/combo-box"
        }}>{`Combo box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/date-picker/"
        }}>{`Date picker`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/date-range-picker/"
        }}>{`Date range picker`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/file-input/"
        }}>{`File input`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/form/"
        }}>{`Form`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/input-prefix-suffix/"
        }}>{`Input prefix or suffix`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/input-mask/"
        }}>{`Input mask`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/memorable-date/"
        }}>{`Memorable date`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/radio-buttons/"
        }}>{`Radio buttons`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/range-slider/"
        }}>{`Range slider`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/search/"
        }}>{`Search`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/select/"
        }}>{`Select`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/text-input/"
        }}>{`Text input`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/time-picker/"
        }}>{`Time picker`}</a>{` (USWDS)`}</li>
    </ul>
    <h2>{`Informational`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/alert/"
        }}>{`Alert`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/banner"
        }}>{`Banner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/footer"
        }}>{`Footer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/identifier/"
        }}>{`Identifier`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/modal/"
        }}>{`Modal`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/process-list/"
        }}>{`Process list`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/site-alert"
        }}>{`Site alert`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/step-indicator/"
        }}>{`Step indicator`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/tag/"
        }}>{`Tag`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/tooltip/"
        }}>{`Tooltip`}</a>{` (USWDS)`}</li>
    </ul>
    <h2>{`Navigational`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/breadcrumb"
        }}>{`Breadcrumb`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/header"
        }}>{`Header`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/in-page-navigation/"
        }}>{`In-page navigation`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/link/"
        }}>{`Link`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/pagination/"
        }}>{`Pagination`}</a>{` (USWDS)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/side-navigation"
        }}>{`Side navigation`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      