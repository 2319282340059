import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Typography helps establish hierarchy which functions to present content clearly and efficiently to users by creating clear visual patterns. NCIDS supports 3 Google fonts, which are easy for designers to embed into Figma and developers to utilize in code.`}</p>
    <p>{`You can download these fonts from Google Fonts:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.google.com/specimen/Poppins"
        }}>{`Poppins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.google.com/specimen/Open+Sans"
        }}>{`Open Sans`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.google.com/specimen/Roboto+Mono"
        }}>{`Roboto Mono`}</a></li>
    </ul>
    <p>{`These fonts and their properties have passed readability from user testing. Stakeholder workshops and user testing demonstrated a greater preference for sans-serif fonts. Poppins was selected for its welcoming and approachable design, while Open Sans was chosen as the body font due to its friendly appearance and legibility across various screen sizes and devices.`}</p>
    <h2>{`Poppins`}</h2>
    <p>{`Poppins is used for component Paragraph Styles and headings.`}</p>
    <h2>{`Open Sans`}</h2>
    <p>{`Open Sans is used for body text, descriptive text, buttons, and in other various component labels.`}</p>
    <h2>{`Roboto Mono`}</h2>
    <p>{`Roboto Mono is only used in code for any place that uses the mono style`}</p>
    <p>{`For more information about font and text styles and how to change the appearance of text, please refer to the following pages:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/typography/text-styles"
        }}>{`Text Styles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/typography/paragraph-styles"
        }}>{`Paragraph Styles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/foundations/typography/fonts"
        }}>{`Font Size & Family`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      