import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
import GridColExample from '../../../src/components/grid-col-example';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <style>{`
	.site-code-preview__showcase > .grid-container > .grid-row > div,
	.site-code-preview__showcase > .grid-row.grid-gap > div > div,
	.site-code-preview__showcase > .grid-row.grid-gap-lg > div > div,
	.site-code-preview__showcase > .grid-row:not(.grid-gap, .grid-gap-lg) > div {
		padding: 16px;
		border: 1px solid black;
		background-color: #f0f0f0;
	}
	.site-code-preview__showcase > .grid-row,
	.site-code-preview__showcase > .grid-container > .grid-row {
		margin-top: 12px;
	}
	.site-code-preview__showcase > .grid-row.grid-gap,
	.site-code-preview__showcase > .grid-row.grid-gap-lg {
		background-color: #dfe1e2;
	}
`}</style>
    <p>{`Structure website content using the flexible grid system. Using USWDS's Layout Grid system, the layout grid is powered by flexbox, mobile-first, and based on a 12-column system.`}</p>
    <h2>{`How it Works`}</h2>
    <p>{`The grid system uses a series of containers, rows, and columns to lay out and align content. The following row and corresponding code are an example of how the grid comes together.`}</p>
    <p>{`This example code creates three equal-width columns on tablet, desktop, and widescreen devices by using our predefined grid classes. The parent `}<inlineCode parentName="p">{`grid-container`}</inlineCode>{` centers the columns on the page.`}</p>
    <p>{`The following sections cover how containers, rows, and columns work as part of the layout grid, and introduce additional functionality available using flexbox.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container">
    <div class="grid-row">
        <div class="tablet:grid-col">tablet:grid-col</div>
        <div class="tablet:grid-col">tablet:grid-col</div>
        <div class="tablet:grid-col">tablet:grid-col</div>
    </div>
</div>
`}</code></pre>
    <p>{`This example code creates three equal-width columns on tablet, desktop, and widescreen devices by using our predefined grid classes. Those columns are centered in the page with the parent `}<inlineCode parentName="p">{`grid-container`}</inlineCode>{` container.`}</p>
    <p>{`The following sections break the layout grid down and describe how it works.`}</p>
    <h3>{`Containers, Rows, and Columns`}</h3>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Containers`}</strong>{`: `}<inlineCode parentName="p">{`grid-container`}</inlineCode>{` centers the container and gives it a maximum width of `}<inlineCode parentName="p">{`widescreen`}</inlineCode>{` (1400px). If you would like the grid to span the full width of the page, do not use `}<inlineCode parentName="p">{`grid-container`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`grid-container`}</inlineCode>{` can also accept any breakpoint width, like `}<inlineCode parentName="p">{`tablet-lg`}</inlineCode>{` or `}<inlineCode parentName="p">{`widescreen`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`By default, `}<inlineCode parentName="p">{`grid-container`}</inlineCode>{` has a `}<inlineCode parentName="p">{`padding-x`}</inlineCode>{` of `}<strong parentName="p"><inlineCode parentName="strong">{`2 units`}</inlineCode></strong>{` at narrow widths, and a `}<inlineCode parentName="p">{`padding-x`}</inlineCode>{` of `}<strong parentName="p"><inlineCode parentName="strong">{`4 units`}</inlineCode></strong>{` at `}<inlineCode parentName="p">{`desktop`}</inlineCode>{` and wider.`}</p>
      </li>
    </ul>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Rows`}</strong>{`: Columns must have a `}<inlineCode parentName="p">{`grid-row`}</inlineCode>{` as a parent.`}</p>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Columns`}</strong>{`: `}<inlineCode parentName="p">{`grid-col-[1-12]`}</inlineCode>{` indicates the number of columns the item spans out of a possible 12 per row. So, if you want three equal-width columns across, use `}<inlineCode parentName="p">{`grid-col-4`}</inlineCode>{` for each item.`}</p>
    <h3>{`Additional Functionality`}</h3>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Equal-width columns`}</strong>{`: With flexbox, grid columns without a specified width will display as equal-width columns. For example, four instances of `}<inlineCode parentName="p">{`grid-col`}</inlineCode>{` will display as one-quarter-width columns across all sizes. Refer to the `}<a parentName="p" {...{
        "href": "#auto-layout-columns"
      }}>{`auto-layout columns`}</a>{` section for more examples.`}</p>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Gutters`}</strong>{`: Rows and columns don’t have any gutters by default, but gutters can be added by including `}<inlineCode parentName="p">{`grid-gap-sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`grid-gap`}</inlineCode>{`, or `}<inlineCode parentName="p">{`grid-gap-lg`}</inlineCode>{` at the row level. Refer to `}<a parentName="p" {...{
        "href": "#gutters"
      }}>{`gutters`}</a>{` for more info.`}</p>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Media queries`}</strong>{`: Grid breakpoints are based on minimum-width media queries, meaning they apply to that specific width and all greater widths (e.g., `}<inlineCode parentName="p">{`tablet:col-4`}</inlineCode>{` applies to `}<inlineCode parentName="p">{`tablet`}</inlineCode>{`, `}<inlineCode parentName="p">{`desktop`}</inlineCode>{`, and `}<inlineCode parentName="p">{`widescreen`}</inlineCode>{` devices but not at `}<inlineCode parentName="p">{`mobile-lg`}</inlineCode>{` or any width below the `}<inlineCode parentName="p">{`tablet`}</inlineCode>{` breakpoint). Refer to `}<a parentName="p" {...{
        "href": "#responsive-variants"
      }}>{`responsive variants`}</a>{` for a full list.`}</p>
    <hr className="measure-6 margin-left-0" />
    <p><strong parentName="p">{`Sass mixins`}</strong>{`: You can use predefined grid classes (like grid-col-4) for presentational markup or Sass mixins for more semantic markup.`}</p>
    <h2 id="responsive-variants">Responsive Variants</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Width`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Utility Class`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Columns`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Gutters`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Nestable`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Smallest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`>= 0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.grid-col`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mobile Large`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`>= 480px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.mobile-lg:grid-col`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Tablet`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`>= 640px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.tablet:grid-col`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Desktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`>= 1024px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.desktop:grid-col`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Widescreen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`>= 1400px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.widescreen:grid-col`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Yes`}</td>
        </tr>
      </tbody>
    </table>
    <h2 id="auto-layout-columns">Auto Layout Columns</h2>
    <h3>{`Variable-width Content`}</h3>
    <p><inlineCode parentName="p">{`.grid-col-auto`}</inlineCode>{` items fit the natural width of their content.`}</p>
    <p><inlineCode parentName="p">{`.grid-col`}</inlineCode>{` and `}<inlineCode parentName="p">{`.grid-col-fill`}</inlineCode>{` items flex to fill the available space as illustrated in the following example row and code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container">
    <div class="grid-row">
        <div class="grid-col-auto">.grid-col-auto</div>
        <div class="grid-col-fill">.grid-col</div>
        <div class="grid-col-fill">.grid-col</div>
        <div class="grid-col-auto">.grid-col-auto</div>
    </div>
</div>
`}</code></pre>
    <h2>{`Responsive Classes`}</h2>
    <h3>{`Same at All Breakpoints`}</h3>
    <p>{`For columns that should maintain the same proportion at any viewport width, use the `}<inlineCode parentName="p">{`.grid-col`}</inlineCode>{` and `}<inlineCode parentName="p">{`.grid-col-*`}</inlineCode>{` classes. Specify a numbered class when you need a column of a specific width; otherwise, use `}<inlineCode parentName="p">{`.grid-col`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`.grid-col-[1-12]`}</inlineCode>{` sets a fixed width of `}{`[n]`}{` grid columns in a 12-column grid.`}</p>
    <GridColExample mdxType="GridColExample" />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-row">
    <div class="grid-col-1">.grid-col-1</div>
    <div class="grid-col-2">.grid-col-2</div>
    <div class="grid-col-3">.grid-col-3</div>
    <div class="grid-col-4">.grid-col-4</div>
    <div class="grid-col-2">.grid-col-2</div>
</div>
<div class="grid-row">
    <div class="grid-col-8">.grid-col-8</div>
    <div class="grid-col-2">.grid-col-2</div>
    <div class="grid-col-2">.grid-col-2</div>
</div>
`}</code></pre>
    <h3>{`Stacked Columns at Narrow Widths`}</h3>
    <p>{`Columns are full-width until the narrowest breakpoint specified in a `}<inlineCode parentName="p">{`.grid-col`}</inlineCode>{` class. For instance, using a single set of `}<inlineCode parentName="p">{`tablet:grid-col-*`}</inlineCode>{` classes, you can create a basic grid system that starts out stacked before displaying as columns at the tablet breakpoint (`}<inlineCode parentName="p">{`tablet:`}</inlineCode>{`) as illustrated in the following rows and corresponding code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-row">
    <div class="tablet:grid-col">.tablet:grid-col</div>
    <div class="tablet:grid-col">.tablet:grid-col</div>
    <div class="tablet:grid-col">.tablet:grid-col</div>
</div>
<div class="grid-row">
    <div class="tablet:grid-col-4">.tablet:grid-col-4</div>
    <div class="tablet:grid-col-8">.tablet:grid-col-8</div>
</div>
`}</code></pre>
    <h3>{`Mix and Match`}</h3>
    <p>{`Don’t want your columns to simply stack in some breakpoints? Use a combination of different classes for each breakpoint as needed. See the following example rows and corresponding code for a better idea of how it all works.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- Stack the columns on mobile by making one full-width and the other half-width -->
<div class="grid-row">
    <div class="tablet:grid-col-8">.tablet:grid-col-8</div>
    <div class="grid-col-6 tablet:grid-col-4">.col-6 .tablet:grid-col-4</div>
</div>
<!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
<div class="grid-row">
    <div class="grid-col-6 tablet:grid-col-4">.col-6 .tablet:grid-col-4</div>
    <div class="grid-col-6 tablet:grid-col-4">.col-6 .tablet:grid-col-4</div>
    <div class="grid-col-6 tablet:grid-col-4">.col-6 .tablet:grid-col-4</div>
</div>
<!-- Columns are always 50% wide, on mobile and desktop -->
<div class="grid-row">
    <div class="grid-col-6">.col-6</div>
    <div class="grid-col-6">.col-6</div>
</div>
`}</code></pre>
    <h2>{`Offsetting Columns`}</h2>
    <p><inlineCode parentName="p">{`.grid-offset-[1-12]`}</inlineCode>{` offsets an item by the specified number of grid columns as shown in the following example.`}</p>
    <GridColExample mdxType="GridColExample" />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-row">
    <div class="grid-col-8 grid-offset-4">.grid-col-8.grid-offset-4</div>
</div>
`}</code></pre>
    <h2>{`Column Wrapping`}</h2>
    <p>{`Items wrap when the sum of the grid columns is more than 12 as shown in the following example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-row">
    <div class="grid-col-8">.grid-col-8</div>
    <div class="grid-col-3">.grid-col-3</div>
    <div class="grid-col-5">.grid-col-5</div>
</div>
`}</code></pre>
    <h2 id="gutters">Gutters</h2>
    <h3>{`Default Gutter`}</h3>
    <p>{`Add `}<inlineCode parentName="p">{`grid-gap`}</inlineCode>{` to a grid row to add a gap (or gutter) between each column in the row. The default gap width is 2 units and 4 units at `}<inlineCode parentName="p">{`desktop`}</inlineCode>{` and higher.`}</p>
    <GridColExample mdxType="GridColExample" />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-row grid-gap">
    <div class="grid-col-4"><div>.grid-col-4</div></div>
    <div class="grid-col-4"><div>.grid-col-4</div></div>
    <div class="grid-col-4"><div>.grid-col-4</div></div>
</div>
`}</code></pre>
    <h3>{`Additional Gutters`}</h3>
    <p>{`To add a larger gap than `}<inlineCode parentName="p">{`grid-gap`}</inlineCode>{`, use `}<inlineCode parentName="p">{`grid-gap-lg`}</inlineCode>{` which will add a 32px (4 spacing units) gap between the columns. For adding a smaller gap than `}<inlineCode parentName="p">{`grid-gap`}</inlineCode>{`, use `}<inlineCode parentName="p">{`grid-gap-sm`}</inlineCode>{` which adds a gap of 2px. Also, you can add the following system values with `}<inlineCode parentName="p">{`grid-gap`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-2px`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-05`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-3`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-5`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.grid-gap-6`}</inlineCode></li>
    </ul>
    <GridColExample mdxType="GridColExample" />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-row grid-gap-lg">
    <div class="grid-col-4"><div>.grid-col-4</div></div>
    <div class="grid-col-4"><div>.grid-col-4</div></div>
    <div class="grid-col-4"><div>.grid-col-4</div></div>
</div>
`}</code></pre>
    <h2>{`Sass Mixins`}</h2>
    <p>{`When generating your CSS from NCIDS source files, you have the option of customizing many system defaults by modifying project theme variables. NCIDS also provides grid mixins for adding grid functionality to custom semantic component CSS.`}</p>
    <h3>{`Variables`}</h3>
    <p>{`Variables and maps determine the number of columns, gutter width, and media-query point at which to begin floating columns. We use variables to generate the predefined grid classes documented on this page, as well as for the custom mixins noted under Utilities settings.`}</p>
    <h4>{`Utilities Settings`}</h4>
    <pre><code parentName="pre" {...{}}>{`// Turn on or off breakpoints
$theme-utility-breakpoints: (
  'card':              false,   // 160px
  'card-lg':           false,   // 240px
  'mobile':            false,   // 320px
  'mobile-lg':         true,    // 480px
  'tablet':            true,    // 640px
  'tablet-lg':         false,   // 880px
  'desktop':           true,    // 1024px
  'desktop-lg':        false,   // 1200px
  'widescreen':        true,   // 1400px
);
`}</code></pre>
    <h3>{`Mixins`}</h3>
    <p>{`Mixins can be used in conjunction with grid variables to add grid functionality to semantic component Sass.`}</p>
    <pre><code parentName="pre" {...{}}>{`// Creates a wrapper for a series of rows
// $container-size can be mobile, mobile-lg, tablet, tablet-lg, desktop, desktop-lg, or widescreen
@include grid-container;
@include grid-container($container-size);

// Creates a wrapper for a series of columns
@include grid-row;

// Specify the width between columns
// $gap-size can be sm, md, lg, 2px, 05, 1, 3, 4, 6
@include grid-gap;
@include grid-gap($gap-size);

// Make the element full-width
@include u-width(full);

// Specify the number of columns the element should span
// $columns can be auto, fill, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
@include grid-col;
@include grid-col($columns);

// Get fancy by offsetting or changing the display order
// $offset can be none, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
@include grid-offset($offset);

// $order can be first, last, initial, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
@include u-order($order);
`}</code></pre>
    <h3>{`Example Usage`}</h3>
    <p>{`You can modify the variables to your custom values or just use the mixins with their default values. Here’s an example of using the default settings to create a two-column layout with a gap.`}</p>
    <pre><code parentName="pre" {...{}}>{`.example-container {
  @include grid-container;
}

.example-row {
  @include grid-row;

  // Add column gaps
  &.content-row {
    @include grid-gap;
  }
}

.example-content-main {
  @include u-width(full);

  @include at-media(tablet) {
    @include grid-col(6);
  }

  @include at-media(desktop) {
    @include grid-col(8);
  }
}

.example-content-secondary {
  @include u-width(full);

  @include at-media(tablet) {
    @include grid-col(6);
  }

  @include at-media(desktop) {
    @include grid-col(4);
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      