"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FooterCollapse = void 0;
class FooterCollapse {
    constructor(element, options) {
        this.customEvents = {};
        this.clickEventListener = () => this.handleClick();
        this.element = element;
        this.options = options;
        this.collapseHeader = document.createElement('button');
        this.heading = (this.element.querySelector(`.${this.options.collapseButtonClass}`));
        this.list = (this.element.querySelector('.usa-list--unstyled'));
        this.listHeader = document.createElement('span');
        this.initialize();
    }
    unregister() {
        this.element.classList.remove('hidden');
        this.toggleCollapseA11y(false);
        const label = this.collapseHeader.innerHTML;
        this.collapseHeader.remove();
        this.listHeader.remove();
        this.list.removeAttribute('id');
        this.list.removeAttribute('aria-label');
        this.heading.classList.add(this.options.collapseButtonClass);
        this.heading.innerHTML = label;
        this.removeEventListeners();
    }
    initialize() {
        this.createCustomEvents();
        this.addEventListeners();
        this.updateDom();
        this.toggleCollapse(true);
    }
    updateDom() {
        const label = this.heading.innerHTML;
        const id = label.replace(/ /g, '-').toLowerCase();
        this.heading.classList.remove(this.options.collapseButtonClass);
        this.heading.innerHTML = '';
        this.listHeader.classList.add(this.options.collapseButtonClass, 'usa-footer__nci-list-header');
        this.listHeader.innerHTML = label;
        this.collapseHeader.classList.add(this.options.collapseButtonClass, 'usa-footer__nci-collapse-header');
        this.collapseHeader.setAttribute('aria-controls', id);
        this.collapseHeader.setAttribute('aria-expanded', 'false');
        this.collapseHeader.innerHTML = label;
        this.list.setAttribute('id', id);
        this.list.setAttribute('aria-label', label);
        this.heading.append(this.listHeader);
        this.heading.append(this.collapseHeader);
    }
    toggleCollapse(hidden) {
        const list = this.element.querySelector('.usa-list');
        list.classList.toggle('hidden', hidden);
        this.toggleCollapseA11y(hidden);
        const event = hidden ? 'collapse' : 'expand';
        this.element.dispatchEvent(this.customEvents[event]);
    }
    toggleCollapseA11y(hidden) {
        this.collapseHeader.setAttribute('aria-expanded', String(!hidden));
        this.list.setAttribute('aria-hidden', String(hidden));
        this.list.hidden = hidden;
        this.list.hidden = hidden;
    }
    addEventListeners() {
        this.collapseHeader.addEventListener('click', this.clickEventListener);
    }
    removeEventListeners() {
        this.collapseHeader.removeEventListener('click', this.clickEventListener);
    }
    handleClick() {
        const list = this.element.querySelector('.usa-list');
        if (list.classList.contains('hidden')) {
            this.toggleCollapse(false);
        }
        else {
            this.toggleCollapse(true);
        }
    }
    createCustomEvents() {
        const events = ['collapse', 'expand'];
        [...events].forEach((event) => {
            this.customEvents[event] = new CustomEvent(`${this.options.collapseEventListenerLabel}:${event}`, {
                bubbles: true,
                detail: this.element,
            });
        });
    }
}
exports.FooterCollapse = FooterCollapse;
