import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
import { ColorChip } from '../../src/components/ColorChip';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The NCIDS is built utilizing the United States Web Design System (USWDS)'s customizable settings. This page will document the settings that have been changed to suit the NCIDS.`}</p>
    <h2>{`Introduction to USWDS Settings`}</h2>
    <p>{`USWDS is built using a suite of `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/documentation/settings/"
      }}>{`customizable settings`}</a>{` that allows you to configure the Design System to fit your project’s needs. These settings are Sass variables that begin with the `}<inlineCode parentName="p">{`$theme-`}</inlineCode>{` prefix and are typically defined with `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/design-tokens"
      }}>{`USWDS design tokens`}</a>{`. You can find the full list of USWDS settings and their default values in the `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/documentation/settings/#general-settings"
      }}>{`USWDS settings tables`}</a>{`.`}</p>
    <h2>{`NCI's Changes to USWDS Setting Tokens`}</h2>
    <h3>{`Breakpoint Changes`}</h3>
    <ul>
      <li parentName="ul">{`The NCIDS enables the `}<inlineCode parentName="li">{`widescreen`}</inlineCode>{` breakpoint for responsive behavior at larger `}<inlineCode parentName="li">{`(>1400px)`}</inlineCode>{` screenspaces.`}</li>
    </ul>
    <h3><a parentName="h3" {...{
        "href": "/foundations/color#theme-tokens"
      }}>{`General Color Changes`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Change`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-primary-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'cerulean-50' to 'cerulean-60'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-secondary-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'navy-60' to 'navy-70'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-accent-warm-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'navy-70' to 'navy-90'`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-accent-cool-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'teal-60' to 'teal-70'`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><a parentName="h3" {...{
        "href": "/foundations/color#state-tokens"
      }}>{`More Specific Color Changes`}</a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Change`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Visualization`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Value Change`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-error-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'red-warm' to 'cranberry'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-warning-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'gold' to 'golden'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-success-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'green-cool' to 'teal'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-info-family'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'cyan' to 'cerulean'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-emergency'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'red-warm-60v' to 'cranberry-60v'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ColorChip color={"#9c3d10"} mdxType="ColorChip" /> - <ColorChip color={"#b60d43"} mdxType="ColorChip" /></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`#9c3d10 to #b60d43`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-color-emergency-dark'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'red-warm-80' to 'cranberry-80'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ColorChip color={"#332d29"} mdxType="ColorChip" /> - <ColorChip color={"#700824"} mdxType="ColorChip" /></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`#332d29 to #700824`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-link-color'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'primary' to 'cerulean-60v'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><ColorChip color={"#007bbd"} mdxType="ColorChip" /> - <ColorChip color={"#01679d"} mdxType="ColorChip" /></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`#007bbd to #01679d`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Shadow Changes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Shadow Token`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`CSS Values (Old to New)`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Visualization`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'shadow-none'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'none'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><div style={{
              "boxShadow": "none"
            }}>{`Shadow None (No Change)`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'shadow-1'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1)'`}</inlineCode><br /><inlineCode parentName="td">{`'box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><div style={{
              "boxShadow": "0 1px 4px 0 rgba(0, 0, 0, 0.1)"
            }}>{`Old Shadow 1`}</div><br /><div className="shadow-1">{`New Shadow 1`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'shadow-2'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1)'`}</inlineCode><br /><inlineCode parentName="td">{`'box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><div style={{
              "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.1)"
            }}>{`Old Shadow 2`}</div><br /><div className="shadow-2">{`New Shadow 2`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'shadow-3'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1)'`}</inlineCode><br /><inlineCode parentName="td">{`'box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><div style={{
              "boxShadow": "0 1px 16px 0 rgba(0, 0, 0, 0.1)"
            }}>{`Old Shadow 3`}</div><br /><div className="shadow-3">{`New Shadow 3`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'shadow-4'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1)'`}</inlineCode><br /><inlineCode parentName="td">{`'box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><div style={{
              "boxShadow": "0 1px 24px 0 rgba(0, 0, 0, 0.1)"
            }}>{`Old Shadow 4`}</div><br /><div className="shadow-4">{`New Shadow 4`}</div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'shadow-5'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1)'`}</inlineCode><br /><inlineCode parentName="td">{`'box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><div style={{
              "boxShadow": "0 1px 32px 0 rgba(0, 0, 0, 0.1)"
            }}>{`Old Shadow 5`}</div><br /><div className="shadow-5">{`New Shadow 5`}</div></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Font Changes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Change`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Value Change`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-type-scale-xs'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'4' to '5'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~15px to ~16px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-type-scale-sm'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'5' to '7'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~16px to ~18px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-type-scale-md'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'6' to '8'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~17px to ~20px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-type-scale-xl'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'12' to '10'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~32px to ~24px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-type-scale-2xl'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'14' to '11'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~40px to ~28px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-type-scale-3xl'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'15' to '12'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~48px to ~32px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-font-weight-semibold'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'600' to 'false'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Disables semibold`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-font-weight-bold'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'700' to '600'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Bold font-weight changes from 700 to 600`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-h4-font-size'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'sm' to 'md'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~18px to ~20px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-h5-font-size'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'xs' to 'sm'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~16px to ~18px`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'$theme-h6-font-size'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'3xs' to 'xs'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`~12px to ~16px`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`NCIDS Import of USWDS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "nopreview",
        "nopreview": true
      }}>{`@use "uswds-core" with (
    $theme-image-path: "@nciocpl/ncids-css/uswds-img",
    $theme-show-notifications: false,
    $theme-show-compile-warnings: false,
    $theme-utility-breakpoints: (
        "card": false,
        "card-lg": false,
        "mobile": false,
        "mobile-lg": true,
        "tablet": true,
        "tablet-lg": true,
        "desktop": true,
        "desktop-lg": false,
        "widescreen": true
    )
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      