import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shadow is used to indicate elevation; shadow tokens determine the size of a drop shadow (or box-shadow as the Cascading Style Sheet, CSS, property) around an item.`}</p>
    <h2>{`Shadow changes in NCIDS`}</h2>
    <p>{`NCIDS has increased the opacity of the CSS property box-shadow from the U.S. Web Design System’s (USWDS’s) 10% to the current 30% alpha value.`}</p>
    <h2>{`Shadow Sizes`}</h2>
    <p>{`The shadow utility has 6 settings (sizes) to choose from that are applied to the CSS property box-shadow: 'none', 1, 2, 3, 4, and 5. Each setting changes the size of the element's drop shadow.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Shadow HTML`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`CSS Value`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Mixin`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><div className="shadow-none">{`Shadow None`}</div></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'none'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include u-shadow('none')`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><div className="shadow-1">{`Shadow 1`}</div></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'0 1px 4px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include u-shadow(1)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><div className="shadow-2">{`Shadow 2`}</div></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'0 4px 8px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include u-shadow(2)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><div className="shadow-3">{`Shadow 3`}</div></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'0 8px 16px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include u-shadow(3)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><div className="shadow-4">{`Shadow 4`}</div></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'0 12px 24px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include u-shadow(4)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><div className="shadow-5">{`Shadow 5`}</div></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`'0 16px 32px 0 rgba(0, 0, 0, 0.3)'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include u-shadow(5)`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`For Designers: How to apply Shadow in the NCIDS Design Kit in Figma`}</h2>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Figma Guide`}</a>{` for how to apply Shadow to an item in Figma.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      