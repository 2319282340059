import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Set width and color of an item’s outline`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Outline`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`0px`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 bg-base-lightest outline-0" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-1px`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 bg-base-lightest outline-2px" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-2px`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`2px`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 bg-base-lightest outline-2px" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-05`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4px`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 bg-base-lightest outline-05" /></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Global color tokens`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Class name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Hex`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-transparent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`transparent`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-transparent" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-white`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`#ffffff`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-darkest"><div className="square-5 margin-2px outline-2px outline-white" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-black`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`#000000`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-black" /></div></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Grayscale color tokens`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Class name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Hex`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-gray-5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#f0f0f0`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-darkest"><div className="square-5 margin-2px outline-2px outline-gray-5" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-gray-10`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#e6e6e6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-darkest"><div className="square-5 margin-2px outline-2px outline-gray-10" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-gray-30`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#adadad`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-gray-30" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-gray-50`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#757575`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-gray-50" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-gray-70`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#454545`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-gray-70" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-gray-90`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#1b1b1b`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-gray-90" /></div></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Basic color tokens`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Class name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Hex`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-teal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#298085`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-teal" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-cerulean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#007bbd`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-cerulean" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-cranberry`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#bb0e3d`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-cranberry" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-navy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#14315c`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-navy" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-golden`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#947100`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-golden" /></div></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Theme color tokens`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Class name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Hex`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base-lightest`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#f0f0f0`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-darkest"><div className="square-5 margin-2px outline-2px outline-base-lightest" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#dfe1e2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-darkest"><div className="square-5 margin-2px outline-2px outline-base-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#a9aeb1`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-base-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#71767a`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-base" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base-dark `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#565c65`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-base-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#3d4551`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-base-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-base-darkest`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#1b1b1b`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-base-darkest" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-ink`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#1b1b1b`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-ink" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-primary-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#99cae4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-primary-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-primary-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#3395ca`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-primary-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#007bbd`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-primary" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-primary-vivid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#067cbc`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-primary-vivid" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-primary-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#004e7a`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-primary-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-primary-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#003a57`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-primary-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-secondary-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#beebee`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-secondary-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-secondary-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#4bbfc6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-secondary-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-secondary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#298085`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-secondary" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-secondary-vivid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#338084`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-secondary-vivid" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-secondary-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#1e4c4f`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-secondary-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-secondary-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#17373a`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-secondary-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-warm-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#fdf2bf`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-warm-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-warm-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#fee685`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-warm-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-warm`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#face00`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-warm-" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-warm-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#ddaa01`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-warm-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-warm-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#b38c00`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-warm-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-cool-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#d7e5f4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-cool-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-cool-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#92a9c8`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-cool-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-cool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#5478ab`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-cool-" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-cool-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#284976`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-cool-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-accent-cool-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#06162d`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-accent-cool-darker" /></div></td>
        </tr>
      </tbody>
    </table>
    <h3>{`State color tokens`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Class name`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Hex`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-error-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#fde2ea`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-error-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-error-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#f27da2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-error-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#e41154`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-error" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-error-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#b60d43`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-error-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-error-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#950b30`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-error-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-warning-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#fdf2bf`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-warning-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-warning-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#ffe396`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-warning-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-warning`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#ffbe2e`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-warning" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-warning-dark `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#e5a000`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-warning-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-warning-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#936f38`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-warning-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-success-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#dbf2f3`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-success-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-success-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#67e4e8`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-success-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-success `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#42979a`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-success" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-success-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#338084`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-success-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-success-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#206b6f`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-success-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-info-lighter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#d4e7f2`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-info-lighter" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-info-light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#99cae4`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-info-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#51b8f0`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-info" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-info-dark `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#2099df`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-info-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-info-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#01679d`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-info-darker" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-disabled-light `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#e6e6e6`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-disabled-light" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#c9c9c9`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-disabled" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-disabled-dark `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#adadad`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-disabled-dark" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-emergency `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#b60d43`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-emergency" /></div></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.outline-emergency-dark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`#700824`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><div className="square-6 padding-2px bg-base-lightest"><div className="square-5 margin-2px outline-2px outline-emergency-dark" /></div></td>
        </tr>
      </tbody>
    </table>
    <p>{`Utilities may be used in component Sass with utility mixins.
Highlighted `}<inlineCode parentName="p">{`tokens`}</inlineCode>{` are the design tokens that serve as the foundation for all design system styles.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Utility`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Mixin`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`.outline-`}<inlineCode parentName="td">{`units`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`u-outline(`}<inlineCode parentName="td">{`units`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include outline('05')`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`.outline-`}<inlineCode parentName="td">{`color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`u-outline(`}<inlineCode parentName="td">{`color`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`@include outline-color('primary-lighter')`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Using utilities mixins`}</h2>
    <ul>
      <li parentName="ul">{`Use single-quoted strings in utility mixins. Do not quote numbers or px values, with the exception of half `}<inlineCode parentName="li">{`(05)`}</inlineCode>{` values like `}<inlineCode parentName="li">{`'05'`}</inlineCode>{`, `}<inlineCode parentName="li">{`'105'`}</inlineCode>{`, and `}<inlineCode parentName="li">{`'205'`}</inlineCode>{` which should be treated as strings.`}</li>
      <li parentName="ul">{`String tokens for half values (05) and negative values (like `}<inlineCode parentName="li">{`neg-1`}</inlineCode>{`)may also be written with their unquoted number token equivalent: `}<inlineCode parentName="li">{`0.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`1.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`2.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`2.5`}</inlineCode>{`, `}<inlineCode parentName="li">{`-1`}</inlineCode>{`, `}<inlineCode parentName="li">{`-1.5`}</inlineCode>{`, etc`}</li>
      <li parentName="ul">{`If multiple utilities share the same prefix (ex: `}<inlineCode parentName="li">{`.text-`}</inlineCode>{` or `}<inlineCode parentName="li">{`.flex-`}</inlineCode>{`) or a single utility accepts multiple kinds of values (ex: .border accepts both border weight and border color), their shared mixin can accept multiple comma-separated values: `}<inlineCode parentName="li">{`u-text('primary-darkest', 'no-underline', 'bold')`}</inlineCode>{` or `}<inlineCode parentName="li">{`u-border-top(2px, 'accent-warm')`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Output any utility mixin as !important by appending !important to the mixin’s argument list: `}<inlineCode parentName="li">{`u-bg('red-warm-50v', !important)`}</inlineCode></li>
    </ul>
    <h2>{`For Designers: How to apply Outline in the NCIDS Design Kit in Figma`}</h2>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Figma Guide`}</a>{` for how to apply Outline to an item in Figma.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      