"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
class Search {
    constructor(searchForm, searchInputFocusHandler, searchInputBlurHandler) {
        this.searchForm = searchForm;
        this.searchInputBlurHandler = searchInputBlurHandler;
        this.searchInputFocusHandler = searchInputFocusHandler;
        this.searchField = (this.searchForm.querySelector('#nci-header-search__field'));
        if (this.searchField) {
            this.initialize();
        }
    }
    initialize() {
        this.searchField.addEventListener('focus', this.searchInputFocusHandler, false);
        this.searchField.addEventListener('focusout', this.searchInputBlurHandler, false);
    }
    static isSearchFormValid() {
        const searchForm = document.querySelector('form.nci-header-search');
        const searchInput = document.querySelector('#nci-header-search__field');
        const searchButton = document.querySelector('button.nci-header-search__search-button');
        if (searchForm && searchInput && searchButton) {
            return true;
        }
        else {
            return false;
        }
    }
    unregister() {
        this.searchField.removeEventListener('focus', this.searchInputFocusHandler, false);
        this.searchField.removeEventListener('focusout', this.searchInputBlurHandler, false);
    }
}
exports.Search = Search;
