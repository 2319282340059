export default [
	{ token: 'micro', pixels: 10 },
	{ token: '1', pixels: 12 },
	{ token: '2', pixels: 13 },
	{ token: '3', pixels: 14 },
	{ token: '4', pixels: 15 },
	{ token: '5', pixels: 16 },
	{ token: '6', pixels: 17 },
	{ token: '7', pixels: 18 },
	{ token: '8', pixels: 20 },
	{ token: '9', pixels: 22 },
	{ token: '10', pixels: 24 },
	{ token: '11', pixels: 28 },
	{ token: '12', pixels: 32 },
	{ token: '13', pixels: 36 },
	{ token: '14', pixels: 40 },
	{ token: '15', pixels: 48 },
	{ token: '16', pixels: 56 },
	{ token: '17', pixels: 64 },
	{ token: '18', pixels: 80 },
	{ token: '19', pixels: 120 },
	{ token: '20', pixels: 140 },
];
