"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileMenu = void 0;
const focus_trap_1 = require("../focus-trap");
const locale = {
    close: {
        en: 'Close Menu',
        es: 'Cerrar menú',
    },
    nav: {
        en: 'Primary navigation.',
        es: 'Navegación primaria.',
    },
};
class MobileMenu {
    constructor(mobileNavElement, adapter) {
        this.menuData = null;
        this.sectionParent = null;
        this.loader = this.createDom('div', [
            'nci-is-loading',
            'hidden',
        ]);
        this.resizeWidth = 1024;
        this.customEvents = {};
        this.linkClickListener = (e) => this.handleLinkClick(e);
        this.menuOpenEventListener = (e) => this.handleOpenMenu(e);
        this.windowResizeEventListener = (query) => {
            if (query.matches) {
                this.handleCloseMenu('Close');
            }
        };
        this.menuCloseButtonEventListener = () => this.handleCloseMenu('Close');
        this.menuCloseOverlayEventListener = () => this.handleCloseMenu('Overlay');
        this.escapeKeyPressListener = (e) => __awaiter(this, void 0, void 0, function* () {
            if (this.activeMenu) {
                const isEscapePressed = e.key === 'Escape';
                if (isEscapePressed) {
                    yield this.closeMenu('Escape');
                }
            }
        });
        if (!adapter.getInitialMenuId) {
            throw new Error('getInitialMenuId required to return a Promise of string or number.');
        }
        if (!adapter.getNavigationLevel) {
            throw new Error('getNavigationLevel required to return a Promise of MobileMenuData.');
        }
        this.element = mobileNavElement;
        this.adapter = adapter;
        this.pageUrl = window.location.pathname;
        this.focusTrap = new focus_trap_1.FocusTrap(this.element);
        this.activeMenu = false;
        this.mobileButton = (this.element.querySelector('.nci-header-mobilenav__open-btn'));
        this.resizeMediaQuery = matchMedia(`(min-width: ${this.resizeWidth}px)`);
        this.langCode = document.documentElement.lang;
        this.initialize();
    }
    unregister() {
        this.element.removeEventListener('click', this.linkClickListener);
        this.mobileButton.removeEventListener('click', this.menuOpenEventListener, true);
        this.mobileClose.removeEventListener('click', this.menuCloseButtonEventListener, true);
        this.mobileOverlay.removeEventListener('click', this.menuCloseOverlayEventListener, true);
        document.removeEventListener('keydown', this.escapeKeyPressListener, false);
        this.resizeMediaQuery.removeEventListener('change', this.windowResizeEventListener);
        this.mobileOverlay.remove();
        this.mobileClose.remove();
        this.mobileNav.remove();
        this.loader.remove();
    }
    initialize() {
        this.mobileNav = (this.createDom('div', ['nci-header-mobilenav'], [
            { tabindex: -1 },
            { role: 'dialog' },
            { 'aria-modal': true },
            { id: 'nci-header-mobilenav' },
        ]));
        this.mobileNav.ariaLive = 'polite';
        this.mobileNav.ariaBusy = 'true';
        this.mobileNav.removeAttribute('hidden');
        this.mobileOverlay = (this.createDom('div', ['nci-header-mobilenav__overlay'], []));
        const ariaLabel = locale['close'][this.langCode];
        this.mobileClose = this.createDom('button', ['nci-header-mobilenav__close-btn'], [
            {
                'aria-controls': 'nci-header-mobilenav',
            },
            {
                'aria-label': ariaLabel,
            },
        ]);
        this.mobileClose.addEventListener('click', this.menuCloseButtonEventListener, true);
        this.mobileOverlay.addEventListener('click', this.menuCloseOverlayEventListener, true);
        this.mobileButton.addEventListener('click', this.menuOpenEventListener, true);
        this.mobileNav.append(this.mobileClose);
        this.mobileNav.append(this.loader);
        this.element.append(this.mobileNav);
        this.element.append(this.mobileOverlay);
        document.addEventListener('keydown', this.escapeKeyPressListener, false);
        this.resizeMediaQuery.addEventListener('change', this.windowResizeEventListener);
        this.createCustomEvents();
    }
    handleOpenMenu(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const menuCheck = this.element.querySelector('.nci-header-mobilenav__list');
            if (menuCheck)
                menuCheck.remove();
            this.mobileNav.ariaBusy = 'true';
            this.loader.classList.remove('hidden');
            const target = event.currentTarget;
            const label = (target.textContent || '').trim();
            yield this.openMenu(label);
        });
    }
    handleCloseMenu(action) {
        this.closeMenu(action);
    }
    openMenu(label) {
        return __awaiter(this, void 0, void 0, function* () {
            this.activeMenu = true;
            this.mobileNav.removeAttribute('hidden');
            this.mobileNav.classList.add('active');
            this.mobileOverlay.classList.toggle('active');
            const initialMenuId = yield this.adapter.getInitialMenuId();
            this.menuData = yield this.adapter.getNavigationLevel(initialMenuId);
            const menu = this.displayNavLevel(this.menuData);
            const menuNav = this.createDom('nav', ['nci-header-mobilenav__nav'], [{ 'aria-label': locale['nav'][this.langCode] }]);
            menuNav.appendChild(menu);
            this.mobileNav.append(menuNav);
            this.mobileClose.focus();
            this.focusTrap.toggleTrap(true, this.mobileNav);
            this.mobileNav.ariaBusy = 'false';
            this.element.dispatchEvent(this.customEvents['open']({
                label: label,
                initialMenu: this.menuData,
            }));
        });
    }
    closeMenu(action) {
        this.activeMenu = false;
        this.mobileNav.setAttribute('hidden', 'hidden');
        this.focusTrap.toggleTrap(false, this.mobileNav);
        this.mobileNav.classList.remove('active');
        this.mobileOverlay.classList.remove('active');
        const lastMenu = this.menuData;
        this.menuData = null;
        this.element.dispatchEvent(this.customEvents['close']({
            action: action,
            lastMenu: lastMenu || null,
        }));
    }
    handleLinkClick(event, action, index) {
        return __awaiter(this, void 0, void 0, function* () {
            const menuCheck = this.element.querySelector('.nci-header-mobilenav__list');
            if (menuCheck)
                menuCheck.remove();
            this.mobileNav.ariaBusy = 'true';
            this.loader.classList.remove('hidden');
            const link = event.target;
            const dataMenuID = link.getAttribute('data-menu-id');
            const target = event.currentTarget;
            const label = (target.textContent || '').trim();
            if (dataMenuID) {
                this.menuData = yield this.adapter.getNavigationLevel(dataMenuID);
                const menu = this.displayNavLevel(this.menuData);
                this.mobileNav.append(menu);
                this.focusTrap.toggleTrap(true, this.mobileNav);
            }
            this.element.dispatchEvent(this.customEvents['linkclick']({
                action: action || null,
                data: this.menuData,
                label: label,
                index: index || null,
            }));
        });
    }
    displayNavLevel(data) {
        const items = data.items;
        this.sectionParent = data.parent;
        const menu = this.createDom('ul', ['nci-header-mobilenav__list']);
        const childItems = items.map((item, index) => {
            index = this.sectionParent ? index + 1 : index;
            return item.hasChildren
                ? this.makeMenuNode(item, index)
                : this.makeMenuLink(item, index);
        });
        if (this.sectionParent) {
            const menuList = this.createDom('ul', ['nci-header-mobilenav__list']);
            const menuBack = this.makeBackNode(this.sectionParent);
            menu.append(menuBack);
            const menuHolder = this.createDom('li', [
                'nci-header-mobilenav__list-holder',
            ]);
            menuHolder.append(menuList);
            const exploreSection = this.makeMenuLink(data, 0);
            menuList.append(exploreSection);
            menu.append(menuHolder);
            menuList.append(...childItems);
        }
        else {
            menu.append(...childItems);
        }
        this.mobileNav.ariaBusy = 'false';
        this.loader.classList.add('hidden');
        return menu;
    }
    makeBackNode(item) {
        const dataMenuID = this.adapter.useUrlForNavigationId ? item.path : item.id;
        const listItem = this.createDom('li', ['nci-header-mobilenav__list-node', 'active'], []);
        const linkLabel = this.createDom('button', ['nci-header-mobilenav__list-msg'], [
            { 'data-menu-id': dataMenuID },
            { 'data-href': item.path },
            { 'data-options': 0 },
            { 'data-isroot': 'false' },
        ]);
        linkLabel.innerHTML = item.label;
        linkLabel.addEventListener('click', (this.linkClickListener = (event) => this.handleLinkClick(event, 'Back')), true);
        listItem.append(linkLabel);
        return listItem;
    }
    makeMenuNode(item, index) {
        const dataMenuID = this.adapter.useUrlForNavigationId ? item.path : item.id;
        const listItem = this.createDom('li', ['nci-header-mobilenav__list-node'], []);
        const linkLabel = this.createDom('button', ['nci-header-mobilenav__list-label'], [
            { 'data-href': item.path },
            { 'data-menu-id': dataMenuID },
            { 'data-options': 0 },
            { 'data-isroot': 'false' },
        ]);
        linkLabel.innerHTML = item.label;
        listItem.addEventListener('click', (this.linkClickListener = (event) => this.handleLinkClick(event, 'Child', index)), true);
        listItem.append(linkLabel);
        return listItem;
    }
    makeMenuLink(item, index) {
        const listItem = this.createDom('li', ['nci-header-mobilenav__list-item'], []);
        const linkItem = this.createDom('a', ['nci-header-mobilenav__list-link'], [{ href: item.path }, { 'data-options': 0 }]);
        if (this.pageUrl === item.path)
            linkItem.classList.add('current');
        linkItem.innerHTML = item.label;
        listItem.addEventListener('click', (this.linkClickListener = (event) => this.handleLinkClick(event, 'Child', index)), true);
        listItem.append(linkItem);
        return listItem;
    }
    createDom(dom, classes, options) {
        const element = document.createElement(dom);
        if (classes) {
            [...classes].forEach((name) => {
                element.classList.add(name);
            });
        }
        if (options) {
            [...options].forEach((opt) => {
                const key = Object.keys(opt)[0];
                const value = Object.values(opt)[0];
                element.setAttribute(key, value);
            });
        }
        return element;
    }
    createCustomEvents() {
        const events = ['close', 'open', 'linkclick'];
        [...events].forEach((event) => {
            this.customEvents[event] = (detail) => new CustomEvent(`nci-header:mobile-menu:${event}`, {
                bubbles: true,
                detail,
            });
        });
    }
}
exports.MobileMenu = MobileMenu;
