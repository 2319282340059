import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Started with NCIDS`}</h1>
    <a href="/get-started/designers" className="header-styled-link">
	For Designers
    </a>
    <p>{`Explore our guidelines, get access to the NCIDS Figma files, and join our Figma community.`}</p>
    <a href="/get-started/developers" className="header-styled-link">
	For Developers
    </a>
    <p>{`Set up your environment using the NCIDS components, utilities, and design tokens.`}</p>
    <a href="/get-started/figma-guide" className="header-styled-link">
	Figma Guides
    </a>
    <p>{`Learn how to use our Figma library and apply design tokens.`}</p>
    <a href="/get-started/maturity-model" className="header-styled-link">
	NCIDS Maturity Model: Adoption and Evaluation
    </a>
    <p>{`Use the NCIDS maturity model to adopt the design system in a way that grows with your team.`}</p>
    <h2>{`Design System Basics`}</h2>
    <a href="/foundations" className="header-styled-link">
	Foundations
    </a>
    <p>{`Explore how you can use design tokens and utilities to implement visual elements such as color, typography, layout, and spacing units, on your website.`}</p>
    <a href="/components" className="header-styled-link">
	Components
    </a>
    <p>{`Use our components as reusable blocks to serve a specific function for users and create consistency within your website.`}</p>
    <a href="/templates" className="header-styled-link">
	Templates
    </a>
    <p>{`Learn how you can use templates to create consistent patterns of components and other elements to promote content, display associations between content items, or highlight specific functionality for users.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      