import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Change the appearance of text. NCIDS uses Poppins Regular, Semibold, and Italics for headings.
Semibold is used for Bold and Italics can be used for headings. Open Sans Regular and Bold are
used for body text and in components.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      