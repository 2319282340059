import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started for Designers`}</h1>
    <p>{`NCIDS is based off the U.S. Web Design System (USWDS) and offers a flexible yet coherent visual style you can apply to all NCI sites. Our visual style adheres to USWDS standards and is tailored to provide users cancer related content in a clean and modern aesthetic that meets usability and accessibility requirements while promoting trust.`}</p>
    <h2>{`Design Libraries and Files`}</h2>
    <p>{`Our design library is in the Figma Community. To download and learn more about our NCDIS Design Kit in Figma, please refer to our `}<a parentName="p" {...{
        "href": "/get-started/figma-guide"
      }}>{`Figma Guide`}</a>{`.`}</p>
    <h2>{`Feedback`}</h2>
    <p>{`Contact the Office of Communications and Public Liaison (OCPL) via the NCI OCPL WebDesignSystem Teams channel for design related support.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      