import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
import UtilitiesTable from '../../src/components/utilities-table';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Utilities are simple HTML classes typically scoped to a single CSS property, like border-style or background-color. Utilities can be used additively to style an object from scratch or to override a style defined in component CSS. Utilities allow designers and developers to build and test new designs and components without abstracting their work into traditional semantic names or altering production CSS.`}</p>
    <p>{`They also make it possible to create element-specific overrides without writing high-specificity variants into component CSS.`}</p>
    <h2>{`Utility Naming`}</h2>
    <p>{`Most USWDS utilities are built to assign a single CSS property a single value. Our utilities are named `}<span className="text-no-wrap"><inlineCode parentName="p">{`.[base]-[value]`}</inlineCode></span>{`, where `}<inlineCode parentName="p">{`[base]`}</inlineCode>{` is often the name of the CSS property the utility targets and the parts of the utility name are separated by a hyphen. For example, `}<inlineCode parentName="p">{`margin-top-2`}</inlineCode>{`. Our utility classes are designed to make sense at a glance to anyone familiar with standard CSS properties.`}</p>
    <p>{`For a complete list of the utilities and their bases, see each utility’s documentation page, or the Modules table, below.`}</p>
    <p>{`The utility values are drawn from your project theme-settings tokens but can be extended to include additional values drawn from our palette of common units (like px, em, ch, and percentages) as well as the broader USWDS system palette of colors, grid spacing, and font scale. (Each utility’s Advanced settings section provides more information on customization.)`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`USWDS Utility`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Sass`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.bg-primary-darker`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`background-color: color('primary-darker')`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.bg-red-50v`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`background-color: color('red-50v')`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.text-underline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`text-decoration: underline`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.margin-x-auto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`margin-left-auto`}</inlineCode><br /><inlineCode parentName="td">{`margin-right-auto`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`.padding-bottom-1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`padding-bottom: units(1)`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Utility Class Modifiers`}</h3>
    <p>{`Utility modules can be configured to output additional CSS classes for targeting specific breakpoints or the link-related pseudo-classes. Each utility module under `}<a parentName="p" {...{
        "href": "/foundations"
      }}>{`Foundations`}</a>{` documents which modifiers are enabled by default.`}</p>
    <h4 id="responsive">Responsive Modifiers</h4>
    <p>{`Add a responsive breakpoint prefix separated with a `}<inlineCode parentName="p">{`:`}</inlineCode>{` to target a utility at a responsive breakpoint and higher, following a mobile-first methodology.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <p><inlineCode parentName="p">{`.tablet:padding-y-2`}</inlineCode></p>
    <p><strong parentName="p">{`Output`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`@media screen and (min-width: 640px) {
    .tablet\\:padding-y-2 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
`}</code></pre>
    <p>{`Set up which breakpoints are available to USWDS utilities by setting the `}<inlineCode parentName="p">{`$theme-utility-breakpoints`}</inlineCode>{` variable in your USWDS settings configuration.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Breakpoint Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Width`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`card`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`160px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`card-lg`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`240px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`mobile`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`320px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`mobile-lg`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`480px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tablet`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`640px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tablet-lg`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`880px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`desktop`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1024px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`desktop-lg`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1200px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`widescreen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1400px`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Set whether a utility family outputs with responsive variants by setting the value of `}<inlineCode parentName="p">{`$[utility_family]-settings.responsive`}</inlineCode>{` in your USWDS settings configuration. When modifying settings stored as a map, undefined map elements will keep their default values.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`@use "uswds-core" with (
  $background-color-settings: (
    responsive: true
  )
);
`}</code></pre>
    <h4 id="state">State Modifiers</h4>
    <p>{`Use a state prefix separated with a `}<inlineCode parentName="p">{`:`}</inlineCode>{` to target a utility in a specific state.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <p><inlineCode parentName="p">{`.hover:bg-primary-vivid`}</inlineCode></p>
    <p><strong parentName="p">{`Output`}</strong></p>
    <p><inlineCode parentName="p">{`.hover\\:bg-primary-vivid:hover { background-color: #0052de; }`}</inlineCode></p>
    <p>{`Set whether a utility outputs a specific state variant by setting the value of the `}<inlineCode parentName="p">{`$[utility_family]-settings.[state]`}</inlineCode>{` variable in your USWDS settings configuration. When modifying settings stored as a map, undefined map elements will keep their default values.`}</p>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`@use "uswds-core" with (
  $background-color-settings: (
    active: true
  )
);
`}</code></pre>
    <h3>{`Utilities Package, Settings, and Modules`}</h3>
    <p>{`USWDS provides a useful default set of utilities that developers can extend and modify in their project’s settings to suit their project’s needs and their development preferences.`}</p>
    <p>{`Utilities are included by default as part of the `}<inlineCode parentName="p">{`uswds`}</inlineCode>{` bundle package that most projects use. (`}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/components/packages/"
      }}>{`Read more about packages here.`}</a>{`)`}</p>
    <p>{`If you unbundle your project’s packages, include utilities with the `}<inlineCode parentName="p">{`uswds-utilities`}</inlineCode>{` package. This package includes all of the USWDS utilities available to the design system. Each utility is configured differently — some have responsive and state variants enabled. Each utility’s default output is documented on its documentation page.`}</p>
    <p>{`The entire package is 198 KB uncompressed.`}</p>
    <p>{`Include the `}<inlineCode parentName="p">{`uswds-utilities`}</inlineCode>{` package in your project’s Sass entry point with a module forward:`}</p>
    <pre><code parentName="pre" {...{}}>{`@forward "uswds-utilities";
`}</code></pre>
    <h4>{`Settings`}</h4>
    <p>{`You can further customize utility output with USWDS settings. Add settings from the table below to your `}<inlineCode parentName="p">{`uswds-core`}</inlineCode>{` module initialization. For more information on configuring USWDS settings, visit the `}<a parentName="p" {...{
        "href": "https://designsystem.digital.gov/documentation/settings#configuring-settings-maps"
      }}>{`settings page`}</a>{`. Here’s an example:`}</p>
    <pre><code parentName="pre" {...{}}>{`@use 'uswds-core';
uswds-core.$output-these-utilities: (
    'padding',
    'box-shadow',
    'font',
    'background-color',
    'font-weight',
    'border-color',
    'line-height',
    'justify-content'
);
// Add aspect ALWAYS outputs no matter the settings. So let's make sure we get
// the smallest possible aspect classes.
uswds-core.$add-aspect-settings: (
    output: false,
    responsive: false,
    active: false,
    focus: false,
    hover: false,
    visited: false
);
uswds-core.$background-color-settings: (
    output: true,
    responsive: false,
    active: false,
    focus: false,
    hover: false,
    // Undo default
    visited: false
);
uswds-core.$box-shadow-settings: (
    output: true,
    responsive: false,
    active: false,
    focus: false,
    hover: false,
    // Undo default
    visited: false
);
uswds-core.$border-color-settings: (
    output: true,
    responsive: false,
    // Undo default
    active: false,
    focus: false,
    hover: false,
    // Undo default
    visited: false
);
uswds-core.$justify-content-settings: (
    output: true,
    responsive: true,
    // Undo default
    active: false,
    focus: false,
    hover: false,
    visited: false
);

@forward 'uswds-utilities';
`}</code></pre>
    <h4>{`Utility Modules`}</h4>
    <UtilitiesTable mdxType="UtilitiesTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      