"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NCIBackToTop = void 0;
class NCIBackToTop {
    constructor(footer, backToTopText) {
        this.scrollListener = () => this.handleScroll();
        this.footer = footer;
        this.backToTopText = backToTopText;
        this.element = this.initialize();
    }
    handleScroll() {
        if (this.element === null)
            return;
        const y = window.scrollY;
        if (y > 0) {
            this.element.classList.remove('hide');
            this.element.classList.add('show');
        }
        else {
            this.element.classList.remove('show');
            this.element.classList.add('hide');
        }
    }
    unregister() {
        window.removeEventListener('scroll', this.scrollListener);
        if (this.element) {
            this.element.remove();
        }
        this.element = null;
    }
    initialize() {
        const backToTopElement = document.createElement('div');
        backToTopElement.classList.add('usa-footer__nci-return-to-top', 'show');
        backToTopElement.setAttribute('aria-label', this.backToTopText);
        const backToTopLink = document.createElement('a');
        backToTopLink.setAttribute('href', '#top');
        const backToTopSpan = document.createElement('span');
        backToTopSpan.textContent = this.backToTopText;
        backToTopLink.append(backToTopSpan);
        backToTopElement.append(backToTopLink);
        this.footer.append(backToTopElement);
        window.addEventListener('scroll', this.scrollListener, false);
        return backToTopElement;
    }
}
exports.NCIBackToTop = NCIBackToTop;
