import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`NCIDS Figma Guide`}</h1>
    <p>{`Jumpstart your designs with ready-made elements in the NCIDS Design Kit.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/community/file/1288512334920909770/ncids-design-kit"
      }}>{`Access NCIDS Design Kit`}</a></p>
    <p>{`Figma is our tool of choice when creating compositions and mockups using our design system. Download an instance of the file from the Figma Community to use in your designs. You will need a Figma account to download the file.`}</p>
    <p><em parentName="p">{`Please note: this page includes a guide of how we have set up the NCIDS Design Kit in Figma. This is not a comprehensive guide on how to use Figma. Please refer to the Figma website’s help articles for any specific questions related to the application.`}</em></p>
    <h2>{`Fonts`}</h2>
    <p>{`Download fonts from Google Fonts`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.google.com/specimen/Poppins"
        }}>{`Poppins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.google.com/specimen/Open+Sans"
        }}>{`Open Sans`}</a></li>
    </ul>
    <p>{`Download the full font family of each font and enable it on your computer to use in Figma.`}</p>
    <h3>{`Poppins`}</h3>
    <p>{`Used for component Paragraph Styles and headings.`}</p>
    <h3>{`Open Sans`}</h3>
    <p>{`Used for body text, descriptive text, buttons, and in other various component labels.`}</p>
    <h2>{`How to use the NCIDS Design Kit as a Library in Figma`}</h2>
    <h3>{`Enabling the Library on your account`}</h3>
    <p>{`Select the ‘Open in Figma’ button on the community page for NCIDS and add this to your personal or team account. The file will be added to your drafts. Once the file is open select the down arrow next to the file name at the top of the page and select ‘publish library.’ Then move it to the team you want to enable the library for. This will allow you to use the library in all your design files. In the ‘Publish Library’ window, make sure all styles and components are enabled. Select the ‘publish’ button, and you’re now ready to use the NCIDS Design Kit in your design comps and mockups.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3f216fc350b2e49ea81e1d14f79e856/dbec1/figma-guide_publish-changes.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.877300613496935%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABzrBI1P/EABcQAAMBAAAAAAAAAAAAAAAAAAABAhH/2gAIAQEAAQUCelQ0KaP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAFhAAAwAAAAAAAAAAAAAAAAAAABDR/9oACAEBAAY/AiL/xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhMZH/2gAIAQEAAT8hi3E/CDqdV0TcZ//aAAwDAQACAAMAAAAQgC//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QS//EABsQAAICAwEAAAAAAAAAAAAAAAERACExQVFh/9oACAEBAAE/EBbEPow+6tkLw8MAc2Xuf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide publish changes",
            "title": "figma guide publish changes",
            "src": "/static/e3f216fc350b2e49ea81e1d14f79e856/6aca1/figma-guide_publish-changes.jpg",
            "srcSet": ["/static/e3f216fc350b2e49ea81e1d14f79e856/d2f63/figma-guide_publish-changes.jpg 163w", "/static/e3f216fc350b2e49ea81e1d14f79e856/c989d/figma-guide_publish-changes.jpg 325w", "/static/e3f216fc350b2e49ea81e1d14f79e856/6aca1/figma-guide_publish-changes.jpg 650w", "/static/e3f216fc350b2e49ea81e1d14f79e856/7c09c/figma-guide_publish-changes.jpg 975w", "/static/e3f216fc350b2e49ea81e1d14f79e856/01ab0/figma-guide_publish-changes.jpg 1300w", "/static/e3f216fc350b2e49ea81e1d14f79e856/dbec1/figma-guide_publish-changes.jpg 1933w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`The Figma file includes NCIDS Components, U.S. Web Design System (USWDS) Pass-Through Components, and Tokens & Utilities`}</h3>
    <p>{`The components, tokens and utilities are organized on pages. The ‘Cover’ page is simply used for a thumbnail. This thumbnail appears when the file is shared as a link.`}</p>
    <p>{`NCIDS Components – components that either have been modified from a USWDS components or new components that have been created for NCI specifically.`}</p>
    <p>{`USWDS Pass-Through Components – components directly from USWDS that have not been modified, except for fonts and colors.`}</p>
    <p>{`Tokens & Utilities – configurable tokens and utilities that can be set in Figma, as well as ones that cannot be set but their styles can be referenced. Tokens and utilities that cannot be set in Figma are noted on those respective artboards.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/211ef90a809e97e42ecbc05e2df07f22/56d85/figma-guide_includes.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.11656441717791%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECBAMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB8+k0NSB2qGgH/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAExAwIh/9oACAEBAAEFAnfUZx0zjpxP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFhABAQEAAAAAAAAAAAAAAAAAARBx/9oACAEBAAY/Amu1v//EABwQAAICAgMAAAAAAAAAAAAAAAABEXExQRAhof/aAAgBAQABPyGTge2WDNv3saLBMmez3cZ7P//aAAwDAQACAAMAAAAQMAcA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARYSExoRBRsfDx/9oACAEBAAE/ENdIG9rrcu6kHeOKrzwQ9g5MxYpTiM3z5Jyvnp7GCf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide includes",
            "title": "figma guide includes",
            "src": "/static/211ef90a809e97e42ecbc05e2df07f22/6aca1/figma-guide_includes.jpg",
            "srcSet": ["/static/211ef90a809e97e42ecbc05e2df07f22/d2f63/figma-guide_includes.jpg 163w", "/static/211ef90a809e97e42ecbc05e2df07f22/c989d/figma-guide_includes.jpg 325w", "/static/211ef90a809e97e42ecbc05e2df07f22/6aca1/figma-guide_includes.jpg 650w", "/static/211ef90a809e97e42ecbc05e2df07f22/56d85/figma-guide_includes.jpg 946w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Components`}</h3>
    <p>{`All components closely align with their corresponding developed variants. We include all components that are live and available to use in the NCIDS. As new components are added, updates to the NCIDS design kit will be available on the Figma Community.`}</p>
    <p>{`Each component is built out for each breakpoint. By default, spacers are turned on in each component’s variant, which shows the spacing units that will match the component in the code. You can simply turn off the spacers by hiding those layers.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/718cadc6eb3d4be0d7e99c46e892efe6/ff5b6/figma-guide_components-spacers01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.3680981595092%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQACBP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAATWxnzF0x//EABkQAQEAAwEAAAAAAAAAAAAAAAECAAMhE//aAAgBAQABBQKnoTkeaJ01GTqJP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAQACAgMAAAAAAAAAAAAAAAEAEQMxEBIz/9oACAEBAAY/AmdTELU8w4G3U2z/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFhUf/aAAgBAQABPyFtzt7LpRR35LpM6OvDt5GFIo75KIfTM//aAAwDAQACAAMAAAAQiw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMVFhobH/2gAIAQEAAT8QCAD9DK/c5wuwr7MCJRB6I9um35hntzWGkfKC2sXc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide components spacers01",
            "title": "figma guide components spacers01",
            "src": "/static/718cadc6eb3d4be0d7e99c46e892efe6/6aca1/figma-guide_components-spacers01.jpg",
            "srcSet": ["/static/718cadc6eb3d4be0d7e99c46e892efe6/d2f63/figma-guide_components-spacers01.jpg 163w", "/static/718cadc6eb3d4be0d7e99c46e892efe6/c989d/figma-guide_components-spacers01.jpg 325w", "/static/718cadc6eb3d4be0d7e99c46e892efe6/6aca1/figma-guide_components-spacers01.jpg 650w", "/static/718cadc6eb3d4be0d7e99c46e892efe6/7c09c/figma-guide_components-spacers01.jpg 975w", "/static/718cadc6eb3d4be0d7e99c46e892efe6/ff5b6/figma-guide_components-spacers01.jpg 1052w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/89aa3df238ff816c1e191d401c5a132a/ff5b6/figma-guide_components-spacers02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.3680981595092%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQACBP/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAATminExdEP8A/8QAGhABAQADAQEAAAAAAAAAAAAAAQIDEiEAEf/aAAgBAQABBQKnoT9jRNemMfTiJP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAcEAEBAAEFAQAAAAAAAAAAAAABABECAxIhMTP/2gAIAQEABj8CbibQuL5hPUasvl63/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFRYbH/2gAIAQEAAT8hTc77KqijvyNyZkdhuWCVCj5KIdMz/9oADAMBAAIAAwAAABAEL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxAwv//EABwQAQEBAQACAwAAAAAAAAAAAAERACExQXGhwf/aAAgBAQABPxCWAa78nSvzni8K/ehQoS30YhX2DEnXPA9sQPA/M+UFqi3f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide components spacers02",
            "title": "figma guide components spacers02",
            "src": "/static/89aa3df238ff816c1e191d401c5a132a/6aca1/figma-guide_components-spacers02.jpg",
            "srcSet": ["/static/89aa3df238ff816c1e191d401c5a132a/d2f63/figma-guide_components-spacers02.jpg 163w", "/static/89aa3df238ff816c1e191d401c5a132a/c989d/figma-guide_components-spacers02.jpg 325w", "/static/89aa3df238ff816c1e191d401c5a132a/6aca1/figma-guide_components-spacers02.jpg 650w", "/static/89aa3df238ff816c1e191d401c5a132a/7c09c/figma-guide_components-spacers02.jpg 975w", "/static/89aa3df238ff816c1e191d401c5a132a/ff5b6/figma-guide_components-spacers02.jpg 1052w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When you want to use a component in your compositions or mockups, access the library from the ‘Resources’ icon in your Figma file. Select the component you want to use, and place it in your comp. You can change the breakpoint and variation of the component on the right bar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9c9403b65ac21d69ce55eb72281b2d3a/64f73/figma-guide_components-resources01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.34355828220859%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAMEBQEC/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABpeo5zSWxi7wdB//EABwQAQACAQUAAAAAAAAAAAAAAAECAwAEERIhM//aAAgBAQABBQId2kJWQppkR9dJ3dXXwixMADP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAfEAACAQIHAAAAAAAAAAAAAAAAARESIQIQMUFRYZH/2gAIAQEABj8CjfkjFU10SqvRFnFiJk0RZZf/xAAdEAADAAICAwAAAAAAAAAAAAAAARExQRAhUaGx/9oACAEBAAE/IYKqM9iWYH1mQZN257wjjRR5IO4LX8hIaE5pcf/aAAwDAQACAAMAAAAQKwA8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8QVY//xAAeEAACAgICAwAAAAAAAAAAAAAAAREhMUFR8HGx8f/aAAgBAQABPxBbkbKT1nQoSFNj9B6TpD6jp+RPnF6vSJTnCncEvul6kdmLQngk/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide components resources01",
            "title": "figma guide components resources01",
            "src": "/static/9c9403b65ac21d69ce55eb72281b2d3a/6aca1/figma-guide_components-resources01.jpg",
            "srcSet": ["/static/9c9403b65ac21d69ce55eb72281b2d3a/d2f63/figma-guide_components-resources01.jpg 163w", "/static/9c9403b65ac21d69ce55eb72281b2d3a/c989d/figma-guide_components-resources01.jpg 325w", "/static/9c9403b65ac21d69ce55eb72281b2d3a/6aca1/figma-guide_components-resources01.jpg 650w", "/static/9c9403b65ac21d69ce55eb72281b2d3a/64f73/figma-guide_components-resources01.jpg 958w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To adjust the text in a component, select the text layer and edit it. To adjust the image, select the image layer, and select a new image in the ‘fill’ layer on the right side. Image crop sizes are defined with placeholder images.`}</p>
    <p>{`If you need to resize the component after placing longer or shorter text blocks, simply drag the bounding box of the component to where the bottom of the text block meets the spacer. Ensure that the top of the spacer meets the bottom of the text’s bounding box, not the bottom of the descender.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4f207cc1ccf494cdd6d8a439eae80ae1/e3528/figma-guide_components-resources02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.18404907975462%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBv/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABnXoHRhqI2xq2Ff/EAB0QAQABAwUAAAAAAAAAAAAAAAIBAxExEhMhIjL/2gAIAQEAAQUCU86u1NQivW2bgExJN5zTx//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABgRAAIDAAAAAAAAAAAAAAAAAAABAxAx/9oACAECAQE/AaiHp//EABsQAAICAwEAAAAAAAAAAAAAAAABAhEQEiEx/9oACAEBAAY/AmOKhHheqGXZw8Q8f//EABwQAAIDAAMBAAAAAAAAAAAAAAABETFBIVFxof/aAAgBAQABPyF1zt6SbnhvA9Q+cPoY2ysm7hkFdxdk73dCqvS/0//aAAwDAQACAAMAAAAQ2wA+/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExEP/aAAgBAgEBPxBZTLH/xAAfEAEAAgEDBQAAAAAAAAAAAAABABFRITFBYYHR8PH/2gAIAQEAAT8QHpbmZYVDsrWxR5iIo0odCXUfZYrDZ2JLVybXbWIz7jmbCb+ITZPkn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide components resources02",
            "title": "figma guide components resources02",
            "src": "/static/4f207cc1ccf494cdd6d8a439eae80ae1/6aca1/figma-guide_components-resources02.jpg",
            "srcSet": ["/static/4f207cc1ccf494cdd6d8a439eae80ae1/d2f63/figma-guide_components-resources02.jpg 163w", "/static/4f207cc1ccf494cdd6d8a439eae80ae1/c989d/figma-guide_components-resources02.jpg 325w", "/static/4f207cc1ccf494cdd6d8a439eae80ae1/6aca1/figma-guide_components-resources02.jpg 650w", "/static/4f207cc1ccf494cdd6d8a439eae80ae1/e3528/figma-guide_components-resources02.jpg 884w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/56bc96910959925f20ccd575f0ec6839/e3528/figma-guide_components-resources03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.15950920245399%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAIGAQMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAAB82wV+xphtLHjNEho/8QAHhAAAgEDBQAAAAAAAAAAAAAAAQIDABESExQhIjH/2gAIAQEAAQUCJ5y7I4YH3SF1jVRtYaaRsllev//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABgRAAIDAAAAAAAAAAAAAAAAAAABAxAR/9oACAECAQE/AajMR//EAB0QAAEEAgMAAAAAAAAAAAAAAAABAhEhEDIxQaH/2gAIAQEABj8CFa1jaNExMqVJp6LfZyf/xAAeEAEAAwABBQEAAAAAAAAAAAABABEhUTFBYYGRsf/aAAgBAQABPyFra/Ze6vLIyUdqBe3rzFXUXiVhe03v9IMzIgiBuf/aAAwDAQACAAMAAAAQvMCA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQAQETH/2gAIAQIBAT8QygK7Hbk//8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFRQWHw0f/aAAgBAQABPxCMDa+XeDMUm0UQ/cUoGgPR6xQ3kOHeIwGWtcGVIC1o5+MWoLb8OSyaQNAwyzDGGjoz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide components resources03",
            "title": "figma guide components resources03",
            "src": "/static/56bc96910959925f20ccd575f0ec6839/6aca1/figma-guide_components-resources03.jpg",
            "srcSet": ["/static/56bc96910959925f20ccd575f0ec6839/d2f63/figma-guide_components-resources03.jpg 163w", "/static/56bc96910959925f20ccd575f0ec6839/c989d/figma-guide_components-resources03.jpg 325w", "/static/56bc96910959925f20ccd575f0ec6839/6aca1/figma-guide_components-resources03.jpg 650w", "/static/56bc96910959925f20ccd575f0ec6839/e3528/figma-guide_components-resources03.jpg 884w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Using our Tokens and Utilities`}</h2>
    <p>{`Maintenance by a dedicated team of designers, developers, information architects, and product managers.`}</p>
    <h3>{`Text Styles & NCI Heading Styles`}</h3>
    <p>{`You can apply Text Styles and NCI Heading Styles to your design compositions or prototypes. We do not recommend editing the text styles that are already configured in components. You can reference Text Styles and Heading styles on the Tokens and Utilities page. Text and NCI Heading Styles can be applied under the “Text” heading on the right-hand side bar of Figma.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6d0c078f49c97ff441297b971b8db8e2/56f86/figma-guide_text-styles.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.079754601226995%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWh2TBK1q//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgQhEBI0/9oACAEBAAEFArEufphvaf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAZEAABBQAAAAAAAAAAAAAAAAAAAiAxMlH/2gAIAQEABj8Cgwupn//EABwQAQABBAMAAAAAAAAAAAAAAAERABAh8XGx0f/aAAgBAQABPyGdlFUlkuTip6/LEmxmXq3/2gAMAwEAAgADAAAAENjP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QU//EAB0QAAEEAgMAAAAAAAAAAAAAAAEAEBEhQfFRYXH/2gAIAQEAAT8QMhgCOdV6HTB1W0YpBlRbf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide text styles",
            "title": "figma guide text styles",
            "src": "/static/6d0c078f49c97ff441297b971b8db8e2/6aca1/figma-guide_text-styles.jpg",
            "srcSet": ["/static/6d0c078f49c97ff441297b971b8db8e2/d2f63/figma-guide_text-styles.jpg 163w", "/static/6d0c078f49c97ff441297b971b8db8e2/c989d/figma-guide_text-styles.jpg 325w", "/static/6d0c078f49c97ff441297b971b8db8e2/6aca1/figma-guide_text-styles.jpg 650w", "/static/6d0c078f49c97ff441297b971b8db8e2/7c09c/figma-guide_text-styles.jpg 975w", "/static/6d0c078f49c97ff441297b971b8db8e2/01ab0/figma-guide_text-styles.jpg 1300w", "/static/6d0c078f49c97ff441297b971b8db8e2/56f86/figma-guide_text-styles.jpg 1572w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Color Styles`}</h3>
    <p>{`We have included our State, Theme and System tokens on the Tokens and Utilities page. Theme and State tokens show in their description what System token they pull from. Color tokens can be applied to components under “Selection Colors” and to shapes under ‘Fill’ by selecting the icon with 4 dots on the right-hand side bar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/12e65/figma-guide_color-styles01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.67484662576687%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQG/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABtsEzYH//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIREyL/2gAIAQEAAQUCjevQkf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ASf/xAAXEAEBAQEAAAAAAAAAAAAAAAAAQQGR/9oACAEBAAY/Au1Gv//EABkQAAIDAQAAAAAAAAAAAAAAAAABIWGRUf/aAAgBAQABPyFdgpCx+un/2gAMAwEAAgADAAAAEP8Az//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QH//EABkQAQEBAQEBAAAAAAAAAAAAAAERMQBBIf/aAAgBAQABPxDJYjVTRwmE591j7T2Um/Xf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide color styles01",
            "title": "figma guide color styles01",
            "src": "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/6aca1/figma-guide_color-styles01.jpg",
            "srcSet": ["/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/d2f63/figma-guide_color-styles01.jpg 163w", "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/c989d/figma-guide_color-styles01.jpg 325w", "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/6aca1/figma-guide_color-styles01.jpg 650w", "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/7c09c/figma-guide_color-styles01.jpg 975w", "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/01ab0/figma-guide_color-styles01.jpg 1300w", "/static/f2df2ff81bf1ef230cfb8fb8e9dd9ff5/12e65/figma-guide_color-styles01.jpg 1856w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4a4685f767e96a65d7d5bca4fe79321f/c06f5/figma-guide_color-styles02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.49079754601227%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAYF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdabCmFf/8QAGRAAAgMBAAAAAAAAAAAAAAAAAhMAARIi/9oACAEBAAEFAirkdMUE/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AW1//8QAGhAAAgIDAAAAAAAAAAAAAAAAABEBMQJxof/aAAgBAQAGPwKdGLdldP/EABoQAAICAwAAAAAAAAAAAAAAAAABETFBYZH/2gAIAQEAAT8hdfkLASq5Nzo//9oADAMBAAIAAwAAABDz7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAAABh/9oACAECAQE/EFn/xAAbEAABBAMAAAAAAAAAAAAAAAARAAEhMUGh8P/aAAgBAQABPxA7aUAunUPHGoWR+fa//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide color styles02",
            "title": "figma guide color styles02",
            "src": "/static/4a4685f767e96a65d7d5bca4fe79321f/6aca1/figma-guide_color-styles02.jpg",
            "srcSet": ["/static/4a4685f767e96a65d7d5bca4fe79321f/d2f63/figma-guide_color-styles02.jpg 163w", "/static/4a4685f767e96a65d7d5bca4fe79321f/c989d/figma-guide_color-styles02.jpg 325w", "/static/4a4685f767e96a65d7d5bca4fe79321f/6aca1/figma-guide_color-styles02.jpg 650w", "/static/4a4685f767e96a65d7d5bca4fe79321f/7c09c/figma-guide_color-styles02.jpg 975w", "/static/4a4685f767e96a65d7d5bca4fe79321f/01ab0/figma-guide_color-styles02.jpg 1300w", "/static/4a4685f767e96a65d7d5bca4fe79321f/c06f5/figma-guide_color-styles02.jpg 1668w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Effect Styles`}</h3>
    <p>{`NCIDS uses the CSS property ‘box-shadow’ to set an item’s drop shadow. In Figma, ‘Box Shadow’ is equivalent to ‘Drop Shadow’ and can be applied under the “Effects” setting from the right-hand side bar.`}</p>
    <h3>{`Grid Styles`}</h3>
    <p>{`The grid is mobile-first, powered by flexbox, and based on a 12-column system. Grids are only visible in editing view in Figma; they are not visible in preview mode. Add a grid to your artboard by selecting the artboard. On the right-hand side, you will see ‘Layout grid’ where you can select the grid style that fits the breakpoint you are designing for.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3d2dd7d307227e26262064be8f56c2cf/8f12b/figma-guide_grid-styles.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.6441717791411%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHblrUc2MD/xAAaEAACAwEBAAAAAAAAAAAAAAABAgMTMQAR/9oACAEBAAEFAgB5I0daYMkArRe//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAQACEBExIf/aAAgBAQAGPwLAnAEXUu4Mj//EABsQAAIDAAMAAAAAAAAAAAAAAAABESExQVGx/9oACAEBAAE/IX08B+0SKGcremBnxzuCh3z0f//aAAwDAQACAAMAAAAQWA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAECAQE/EBNX/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIWExcbHh/9oACAEBAAE/EOWKjkxcFmDK6dT2Q0RV0HkU4xhDGGKVn4G5/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "figma guide grid styles",
            "title": "figma guide grid styles",
            "src": "/static/3d2dd7d307227e26262064be8f56c2cf/6aca1/figma-guide_grid-styles.jpg",
            "srcSet": ["/static/3d2dd7d307227e26262064be8f56c2cf/d2f63/figma-guide_grid-styles.jpg 163w", "/static/3d2dd7d307227e26262064be8f56c2cf/c989d/figma-guide_grid-styles.jpg 325w", "/static/3d2dd7d307227e26262064be8f56c2cf/6aca1/figma-guide_grid-styles.jpg 650w", "/static/3d2dd7d307227e26262064be8f56c2cf/7c09c/figma-guide_grid-styles.jpg 975w", "/static/3d2dd7d307227e26262064be8f56c2cf/01ab0/figma-guide_grid-styles.jpg 1300w", "/static/3d2dd7d307227e26262064be8f56c2cf/8f12b/figma-guide_grid-styles.jpg 1506w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Applying other utilities that are not added to the NCIDS Design Kit library`}</h3>
    <p>{`On the Tokens and Utilities page you will see other utilities you can apply that are not added to the NCIDS Design Kit Library on the right-hand side bar when you are designing a composition or prototype. Border, border radius and outline are all utilities that can be referenced on this page on how to apply these styles to your designs.`}</p>
    <h3>{`Spacing Units`}</h3>
    <p>{`Spacing unit tokens are based on multiples of 8 and are used in all components and between components. Spacing units are applied to components in the NCIDS Design Kit and are visible by default. When placing the components in your designs, spacers can be turned off by ‘hiding’ the spacers layer on the left-hand bar. Examples of how to place spacing tokens between components can be seen in our template files.`}</p>
    <h3>{`Using Our Templates`}</h3>
    <p>{`We have provided template files which let you see how components will stack on a page at each breakpoint which also include component patterns such as 3-Card Row and Guide Card Rows. Use these templates to kickstart your design compositions or prototypes.`}</p>
    <h3>{`Brand Usage Guidelines`}</h3>
    <p>{`We have provided logos for various Divisions, Offices, and Centers (DOC’s) on the Brand page.`}</p>
    <h3>{`Reviewing Updates`}</h3>
    <p>{`When updates to the library are available, you will receive a notification in Figma that there are updates to the NCIDS Design Kit library. Review the updates before accepting them as they may break your layout or design and you will not be able to undo those changes. We recommend saving your version history of files in case you need to revert changes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      