import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/component-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const NciDsJsInit = makeShortcode("NciDsJsInit");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <NciDsJsInit path={props.pageContext.pagePath} mdxType="NciDsJsInit">{`
	const accordions = Array.from(document.querySelectorAll('.usa-accordion'));
	accordions.forEach((element) => {
		if (element.firstChild.nextElementSibling.classList.contains('usa-accordion__heading')) return;
		const htmlElement = element;
		// Check if Accordion is Multiselectable
		const isMultiSelect =
			htmlElement.hasAttribute('data-allow-multiple') ||
			htmlElement.classList.contains('usa-accordion--multiselectable');
		// Return array of open sections, default is [1] (first section)
		const openSections = htmlElement.dataset.openSections || '1';
		let dataOpenSections = JSON.parse(
			'[' + htmlElement.dataset.openSections + ']'
		);
		// Return accordion with default options
		// set multiselect / set open sections
		window.ncids.USAAccordion.create(htmlElement, {
			allowMultipleOpen: isMultiSelect,
			openSections: dataOpenSections,
		});
	});
`}</NciDsJsInit>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      