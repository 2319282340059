"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NCIExtendedHeaderWithMegaMenu = void 0;
const default_mega_menu_source_1 = require("../mega-menu/default-mega-menu-source");
const default_mobile_menu_source_1 = require("../mobile-menu/default-mobile-menu-source");
const mega_menu_nav_1 = require("../utils/mega-menu/mega-menu-nav");
const mobile_menu_1 = require("../utils/mobile-menu/mobile-menu");
const search_1 = require("../utils/search");
class NCIExtendedHeaderWithMegaMenu {
    constructor(element, options) {
        this.searchInputFocusHandler = (event) => this.handleSearchFocus(event);
        this.searchInputBlurHandler = (event) => this.handleSearchBlur(event);
        this.element = element;
        this.options = options;
        this.megaMenuNav = this.wireUpMegaMenu();
        this.mobileMenu = this.wireUpMobileMenu();
        const searchFormEl = this.element.querySelector('form.nci-header-search');
        if (searchFormEl) {
            this.searchForm = new search_1.Search(searchFormEl, this.searchInputFocusHandler, this.searchInputBlurHandler);
        }
        const valid = search_1.Search.isSearchFormValid();
        if (valid) {
            this.searchDiv = this.element.querySelector('.nci-header-nav__secondary');
        }
        const existingComponent = NCIExtendedHeaderWithMegaMenu._components.get(this.element);
        if (existingComponent) {
            existingComponent.unregister();
        }
        NCIExtendedHeaderWithMegaMenu._components.set(this.element, this);
    }
    static create(element, options) {
        if (!(element instanceof HTMLElement)) {
            throw 'Element is not an HTMLElement';
        }
        return this._components.get(element) || new this(element, options);
    }
    static autoInit() {
        document.addEventListener('DOMContentLoaded', () => {
            const headers = Array.from(document.getElementsByClassName('nci-header'));
            headers.forEach((header) => {
                this.create(header, {
                    megaMenuSource: new default_mega_menu_source_1.DefaultMegaMenuSource(),
                    mobileMenuSource: new default_mobile_menu_source_1.DefaultMobileMenuSource(),
                });
            });
        });
    }
    unregister() {
        if (this.searchForm) {
            this.searchForm.unregister();
        }
        this.megaMenuNav.unregister();
        this.mobileMenu.unregister();
        NCIExtendedHeaderWithMegaMenu._components.delete(this.element);
    }
    wireUpMegaMenu() {
        const navigation = this.element.querySelector('.nci-header-nav__primary');
        return new mega_menu_nav_1.MegaMenuNav(navigation, this.options.megaMenuSource);
    }
    wireUpMobileMenu() {
        const navigation = this.element;
        return new mobile_menu_1.MobileMenu(navigation, this.options.mobileMenuSource);
    }
    handleSearchFocus(event) {
        event.preventDefault();
        this.searchDiv.classList.add('search-focused');
    }
    handleSearchBlur(event) {
        event.preventDefault();
        setTimeout(() => {
            this.searchDiv.classList.remove('search-focused');
        }, 250);
    }
}
exports.NCIExtendedHeaderWithMegaMenu = NCIExtendedHeaderWithMegaMenu;
NCIExtendedHeaderWithMegaMenu._components = new Map();
