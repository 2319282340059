import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The layout and positioning of an item can be set by the following tokens and utilities.`}</p>
    <a href="/foundations/layout-positioning/flex" className="header-styled-link">
	Flex
    </a>
    <p>{`Set an item’s flexbox properties.`}</p>
    <a href="/foundations/layout-positioning/display" className="header-styled-link">
	Display
    </a>
    <p>{`Control the position and display characteristics of an item.`}</p>
    <a href="/foundations/layout-positioning/float" className="header-styled-link">
	Float
    </a>
    <p>{`Float an item within a layout flow.`}</p>
    <a href="/foundations/layout-positioning/layout-grid" className="header-styled-link">
	Layout Grid
    </a>
    <p>{`Use a flexible grid to organize or layout a page or page section.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      