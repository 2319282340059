import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/utility-page-layout/utility-page-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A customized display and label heading, and H1-H6 styles utilizing USWDS theme tokens.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/4FeMfprVErO6esR8FLczp5/NCIDS-Design-Kit?type=design&node-id=5294%3A10398&mode=design&t=lGwrxbWFW3JAwAet-1"
      }}>{`View in Figma`}</a></p>
    <h2>{`Usage`}</h2>
    <ul>
      <li parentName="ul">{`Use heading display if you'd like the heading to be responsive between tablet and large tablet.`}</li>
      <li parentName="ul">{`Use the label modifier to introduce a label to your content that has a horizontal line under the section. This horizontal line extends the width of the page.`}</li>
      <li parentName="ul">{`Use Heading 1-Heading 6 (H1-H6) style headings to convey the content hierarchy of your page, as well as the semantic function of your text — this will help search engines to interpret the meaning and structure of your content.`}
        <ul parentName="li">
          <li parentName="ul">{`Note: Ensure that your headings use relevant keywords and accurately describe the content they are introducing.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Do not skip heading levels. For example, H2 headings should not be followed immediately by an H4 heading — make sure you use an H3 instead of an H4, in this case.`}</li>
      <li parentName="ul">{`Do not use text formatting (bold, italics, etc.) in place of headings.`}</li>
    </ul>
    <h3>{`Where to use`}</h3>
    <ul>
      <li parentName="ul">{`Use headings on home and landing pages, as well as on inner pages.`}</li>
    </ul>
    <h3>{`When to use`}</h3>
    <ul>
      <li parentName="ul">{`Any element can be styled as a heading by using the appropriate heading classes.`}</li>
      <li parentName="ul">{`Traditional heading elements (H1-H6) are designed to work best in the body of your content.`}
        <ul parentName="li">
          <li parentName="ul">{`Use your H1 tag and class for your page title`}</li>
          <li parentName="ul">{`Use your H2 tag and class to introduce major sections, and subsequent headings H3-H6 for subsections.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`When you need a heading to stand out, use a display heading — a larger, slightly more opinionated heading style.`}
        <ul parentName="li">
          <li parentName="ul">{`Only consider applying the display class to your page title (H1), as this class will make your heading slightly larger than any element styled with an H1 class.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`When to consider something else`}</h3>
    <ul>
      <li parentName="ul">{`If an element is not styled using a heading class, the `}<a parentName="li" {...{
          "href": "https://designsystem.digital.gov/components/prose/"
        }}>{`USWDS prose class`}</a>{` can be used to style an element within blocks of text in a WYSIWYG where it’s more difficult to add custom classes to individual elements.`}</li>
    </ul>
    <h2>{`Best Practices`}</h2>
    <h3>{`Character limits`}</h3>
    <p>{`Character limits are strongly recommended to ensure text is concise, scannable, and not visually distorted.`}</p>
    <p><em parentName="p">{`Character limits are based on best practices and when a line of text will be forced to wrap onto a second line of text on desktop.`}</em></p>
    <ul>
      <li parentName="ul">{`Keep H1’s between 60-70 characters long. This will help to avoid truncation of your title by search engines.`}</li>
      <li parentName="ul">{`Keep subheadings (H2-H6) under 50 characters long to create a content hierarchy that’s easy to scan by users, allowing them to find information quicker within your content.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <p><strong parentName="p">{`Follow best practices regarding heading usage.`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use only one H1 per page.`}</strong>{` The H1 heading is the most important ranking on the page and should be reserved for the page title to describe the page’s overall content.`}</li>
      <li parentName="ul"><strong parentName="li">{`Avoid skipping heading levels.`}</strong>{` Skipping heading levels can cause confusion for those using the document outline or headings for in-page navigation. For example, H2 headings should not be followed immediately by an H4 heading.`}</li>
    </ul>
    <p>{`For more on accessible best practices, refer to `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/page-structure/headings/"
      }}>{`W3C guidance on headings`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<section class="usa-section">
    <div class="grid-container">
        <h1 class="nci-heading-display">nci-heading-display</h1>
        <h1 class="nci-heading-h1">nci-heading-h1</h1>
        <h2 class="nci-heading-h2">nci-heading-h2</h2>
        <h3 class="nci-heading-h3">nci-heading-h3</h3>
        <h4 class="nci-heading-h4">nci-heading-h4</h4>
        <h5 class="nci-heading-h5">nci-heading-h5</h5>
        <h6 class="nci-heading-h6">nci-heading-h6</h6>
        <h1 class="nci-heading-h1 nci-heading--label">
            nci-heading-h1 nci-heading--label
        </h1>
        <h2 class="nci-heading-h2 nci-heading--label">
            nci-heading-h2 nci-heading--label
        </h2>
        <h3 class="nci-heading-h3 nci-heading--label">
            nci-heading-h3 nci-heading--label
        </h3>
        <h4 class="nci-heading-h4 nci-heading--label">
            nci-heading-h4 nci-heading--label
        </h4>
        <h5 class="nci-heading-h5 nci-heading--label">
            nci-heading-h5 nci-heading--label
        </h5>
        <h6 class="nci-heading-h6 nci-heading--label">
            nci-heading-h6 nci-heading--label
        </h6>
    </div>
</section>
`}</code></pre>
    <h2>{`Package`}</h2>
    <p>{`Import this Sass partial into your stylesheet`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "nopreview",
        "nopreview": true
      }}>{`@forward "nci-heading";
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      