import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/component-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const NciDsJsInit = makeShortcode("NciDsJsInit");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <NciDsJsInit path={props.pageContext.pagePath} mdxType="NciDsJsInit">{`
	const elements = preview.querySelectorAll('.usa-combo-box');
	elements.forEach((element) => {
		window.ncids.USAComboBox.create(element);
	});
`}</NciDsJsInit>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      