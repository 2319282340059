import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bryanp/Development/tmp/ncids-3.0.0/docs/src/components/layouts/default-layout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Summary Box Row`}</h1>
    <p>{`A summary box row is a pattern of two summary box components each displaying two different types of key information or next steps.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/4FeMfprVErO6esR8FLczp5/NCIDS-Design-Kit?type=design&node-id=5469%3A8156&mode=design&t=9xNemHVdk0y2K4UA-1"
      }}>{`View in Figma`}</a></p>
    <h2>{`Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0c247f0b69d3ba9977b60753748c2603/6437c/summary-box-row.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.447852760736193%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdsJQv8A/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERQWH/2gAIAQEAAT8hIro+H//aAAwDAQACAAMAAAAQ9A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMUH/2gAIAQEAAT8QA97bqNWH4OQvEN//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a summary box row",
            "title": "Example of a summary box row",
            "src": "/static/0c247f0b69d3ba9977b60753748c2603/6aca1/summary-box-row.jpg",
            "srcSet": ["/static/0c247f0b69d3ba9977b60753748c2603/d2f63/summary-box-row.jpg 163w", "/static/0c247f0b69d3ba9977b60753748c2603/c989d/summary-box-row.jpg 325w", "/static/0c247f0b69d3ba9977b60753748c2603/6aca1/summary-box-row.jpg 650w", "/static/0c247f0b69d3ba9977b60753748c2603/7c09c/summary-box-row.jpg 975w", "/static/0c247f0b69d3ba9977b60753748c2603/01ab0/summary-box-row.jpg 1300w", "/static/0c247f0b69d3ba9977b60753748c2603/6437c/summary-box-row.jpg 2116w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It is recommended that a summary box row be composed of two summary box components. Refer to the `}<a parentName="p" {...{
        "href": "/components/summary-box"
      }}>{`Summary Box`}</a>{` for specific attributes of that component.`}</p>
    <h2>{`Usage`}</h2>
    <h3>{`Where to use`}</h3>
    <ul>
      <li parentName="ul">{`Use component on landing and inner pages.`}</li>
    </ul>
    <h3>{`When to use`}</h3>
    <ul>
      <li parentName="ul">{`Use if aiming to provide two different sets of key information, next steps or CTAs at the start of your page. Refer to the `}<a parentName="li" {...{
          "href": "/components/summary-box"
        }}>{`Summary Box Component`}</a>{` for alternative guidance.`}</li>
    </ul>
    <h2>{`Best Practices`}</h2>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/components/summary-box"
      }}>{`Summary Box Component`}</a>{` for best practice recommendations specific to the summary box.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container">
    <div
        class="grid-row tablet-lg:grid-gap-4 grid-gap-2 flex-row flex-align-stretch"
    >
        <div class="tablet:grid-col-6 display-flex flex-align-stretch">
            <div
                class="usa-summary-box"
                role="region"
                aria-labelledby="summary-box-key-information"
            >
                <div class="usa-summary-box__body">
                    <h3 class="usa-summary-box__heading" id="summary-box-key-information">
                        Common Cancer Types
                    </h3>
                    <div class="usa-summary-box__text">
                        <div class="grid-row grid-gap-3">
                            <ul class="usa-list usa-list--unstyled tablet-lg:grid-col-6">
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Lung Cancer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Melanoma
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Non-Hodgkin Lymphoma
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Pancreatic Cancer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Prostate Cancer
                                    </a>
                                </li>
                            </ul>
                            <ul class="usa-list usa-list--unstyled tablet-lg:grid-col-6">
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Bladder Cancer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Breast Cancer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Colorectal Cancer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Endometrial Cancer
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="usa-summary-box__link text-no-underline hover:text-underline"
                                        href="#"
                                    >
                                        Kidney Cancer
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tablet:grid-col-6 display-flex flex-align-stretch">
            <div
                class="usa-summary-box"
                role="region"
                aria-labelledby="summary-box-key-information"
            >
                <div class="usa-summary-box__body">
                    <h3 class="usa-summary-box__heading" id="summary-box-key-information">
                        Advanced and Ongoing Diagnosis
                    </h3>
                    <div class="usa-summary-box__text">
                        <ul class="usa-list usa-list--unstyled">
                            <li>
                                <a
                                    class="usa-summary-box__link text-no-underline hover:text-underline"
                                    href="#"
                                >
                                    Advanced Cancer
                                </a>
                            </li>
                            <li>
                                <a
                                    class="usa-summary-box__link text-no-underline hover:text-underline"
                                    href="#"
                                >
                                    Managing Cancer Care
                                </a>
                            </li>
                            <li>
                                <a
                                    class="usa-summary-box__link text-no-underline hover:text-underline"
                                    href="#"
                                >
                                    Metastatic Cancer
                                </a>
                            </li>
                            <li>
                                <a
                                    class="usa-summary-box__link text-no-underline hover:text-underline"
                                    href="#"
                                >
                                    Recurrent Cancer
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
`}</code></pre>
    <h2>{`Package`}</h2>
    <p>{`Import this Sass partial into your stylesheet`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss",
        "metastring": "nopreview",
        "nopreview": true
      }}>{`@forward "usa-summary-box";
@forward "usa-layout-grid";
@forward "usa-list";
@forward "uswds-utilities";
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      